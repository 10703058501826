import InfoCard from "../../components/common/cards/InfoCard";

export default function DimensionCard({
  dimensionInfo,
  picture,
  teaserHtml,
  small,
  transparent,
  light,
  hideSubtitle,
  expandedText,
  getEmbedInfo,
  linkLabel,
  expandedOnly,
  list,
  stackedBarData,
  icon,
  medley
}) {
  const preview = teaserHtml
    ? dimensionInfo.preview.concat(teaserHtml)
    : dimensionInfo.preview;

  return (
    <InfoCard
      title={dimensionInfo.label}
      subTitle={dimensionInfo.subTopics
        .filter((subTopic) => subTopic.label !== "Overview")
        .map((subTopic) => subTopic.label)
        .join(", ")}
      color={dimensionInfo.primaryColor}
      link={dimensionInfo.link}
      linkLabel={linkLabel ?? `Explore Insight`}
      picture={picture}
      small={small}
      preview={expandedOnly ? expandedText : preview}
      transparent={transparent}
      light={light}
      hideSubtitle={hideSubtitle}
      expandedText={expandedOnly ? null : expandedText}
      getEmbedInfo={getEmbedInfo}
      subTitleList={
        list
          ? dimensionInfo.subTopics
              .filter((subTopic) => subTopic.label !== "Overview")
              .map((subTopic) => subTopic.label)
          : null
      }
      stackedBarData = {stackedBarData}
      icon={icon}
      medley = {medley}
      secondaryColor={dimensionInfo.secondaryColor}
    />
  );
}