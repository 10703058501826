import { useState, useEffect } from "react";
import { Helmet } from 'react-helmet-async';

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";


import { MAP } from "../../../config";
import Locations from "../../../assets/content/neighborhoods.json";
import InfoModalButton from "../../common/buttons/InfoModalButton";
import SectionHeader from "../../common/banners/SectionHeader";
import MultipleCheckFilter from "../../common/filters/MultipleCheckFilter";
import SingleRadioFilter from "../../common/filters/SingleRadioFilter";

import { TagTwoTone, Tune } from "@mui/icons-material";

import MapView from "./MapView";
import ListView from "./ListView";
import {
  useQueryArg,
  MAP_QUERY_ARGS,
  getParentTagFromTagString,
} from "../../../utils/utils";

import Speakers from "../../../assets/content/speakers.json";

import Affects from "../../../assets/content/affects-rtfc.json"

function MapViewContainer({ topics, prompts, highlights, affects }) {
  const [
    filteredHighlightsWithLocationFilter,
    setFilteredHighlightsWithLocationFilter,
  ] = useState(highlights);
  const [
    filteredHighlightsWithoutLocationFilter,
    setFilteredHighlightsWithoutLocationFilter,
  ] = useState(highlights);

  const [currLocation, setCurrLocation] = useState(
    useQueryArg(MAP_QUERY_ARGS.location) ?? null
  );

  const [promptFilter, setPromptFilter] = useState(
    useQueryArg(MAP_QUERY_ARGS.prompt) ?? null
  );



  const [isMapOn, setIsMapOn] = useState(
    useQueryArg(MAP_QUERY_ARGS.hideMap) ? false : true
  );

  const topicQueryArg = useQueryArg(MAP_QUERY_ARGS.topic);
  const topicQueryInfo = topics.find((topic) => topic.label === topicQueryArg);
  const [topicFilters, setTopicFilters] = useState(
    topicQueryArg ? [topicQueryInfo] : []
  );

  //NEW
  const affectQueryArg = useQueryArg(MAP_QUERY_ARGS.affect); // since affect is similar to topic, copied the topic statement above....idk if it works. 
  const affectQueryInfo = affects.find((affect) => affect.label === affectQueryArg);
  const [affectFilters, setAffectFilters] = useState(
    affectQueryArg ? [affectQueryInfo] : []
  );


  useEffect(() => {
    document.getElementById("highlight-scroll").scrollTop = 0;
  }, [filteredHighlightsWithLocationFilter]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = (region) => {
    if (currLocation === region) {
      setCurrLocation(null);
    } else {
      setCurrLocation(region);
    }
  };

  const handleClose = () => {
    setCurrLocation(null);
  };

  const handleMapToggle = () => {
    setIsMapOn(!isMapOn);
  };

  const handleDeleteTopic = (item) => {
    setTopicFilters(topicFilters.filter((x) => x !== item));
  };


  const handleDeletePrompt = () => {
    setPromptFilter(null);
  };

  const handleDeleteLocation = () => {
    setCurrLocation(null);
  };

  //ADDED THIS
  const handleDeleteAffect = (item) => {
    setAffectFilters(affectFilters.filter((x) => x !== item));
  };


  useEffect(() => {
    let filtered = highlights;

    if (topicFilters.length > 0) {
      for (let topic of topicFilters) {
        filtered = filtered.filter((highlight) => {
          const tags = highlight.tags;
          for (var tag of tags) {
            const parent = getParentTagFromTagString(tag);
            if (topic.label.toUpperCase() === parent.toUpperCase()) return true;
          }
          return false;
        });
      }
    }


    // NEW - for AFFECT FILTER
    if (affectFilters.length > 0) {
      for (let affect of affectFilters) {
        filtered = filtered.filter((highlight) => {
          const tags = highlight.community_affect;
          for (var tag of tags) {
            if (affect.label.toUpperCase() === tag.toUpperCase()) return true;
          }
          return false;
        });
      }
    }

    if (promptFilter) {
      filtered = filtered.filter(
        (highlight) => promptFilter.toLowerCase() === highlight.prompt.toLowerCase() 
      );
    }

    console.log(promptFilter); 


    setFilteredHighlightsWithoutLocationFilter(filtered);

    if (currLocation) {
      filtered = filtered.filter((highlight) => {
        const speakerInfo = Speakers.find((speaker) => {
          if (speaker.name === highlight.speaker)
            return speaker.name === highlight.speaker;

          return false;
        });

        return (
          speakerInfo?.demographics[MAP.demographicId].toUpperCase() ===
          currLocation?.toUpperCase()
        );
      });
    }

    setFilteredHighlightsWithLocationFilter(filtered);
  }, [highlights, currLocation, promptFilter, topicFilters, affectFilters]);

  return (
    <Grid>
      <Helmet>
       <title>Map Explorer</title>
       <meta name='description' content='Explore voices through neighborhood map' />
     </Helmet>
      <Grid container>
        <SectionHeader
          title={`Voices By ${MAP.label}`}
          info={
            <InfoModalButton
              title="Filters"
              icon={
                <span style={{ position: "relative", top: "-4.25px" }}>
                  <Tune
                    sx={{
                      fontSize: "20px",
                      position: "relative",
                      top: "5px",
                    }}
                  />
                  <span style={{ paddingLeft: ".5rem" }}>Filters</span>
                </span>
              }
              buttonLabel="Close"
            >
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <SingleRadioFilter
                  items={[
                    { label: `All ${MAP.label}s`, id: null },
                    ...Locations,
                  ]}
                  defaultName={`${MAP.label}s`}
                  selectedItem={currLocation}
                  setSelectedItem={setCurrLocation}
                />
              </Box>
              <Box sx={{ display: { xs: "block", sm: "none" } }}>
                <SingleRadioFilter
                  items={[
                    { label: `All ${MAP.label}s`, id: null },
                    ...Locations,
                  ]}
                  defaultName={`${MAP.label}s`}
                  selectedItem={currLocation}
                  setSelectedItem={setCurrLocation}
                  singleColumn
                />
              </Box>
              
              {prompts && (
                <SingleRadioFilter
                  items={[{ label: `All Prompts`, id: null }, ...prompts]}
                  defaultName="Prompts"
                  selectedItem={promptFilter}
                  setSelectedItem={setPromptFilter}
                  singleColumn
                  promptFilter
                />
              )}


              {topics && (
                <>
                  <Box sx={{ display: { xs: "block", sm: "none" } }}>
                    <MultipleCheckFilter
                      items={topics}
                      defaultName="Themes"
                      selectedItems={topicFilters}
                      setSelectedItems={setTopicFilters}
                      singleColumn
                    />
                  </Box>
                  <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    <MultipleCheckFilter
                      items={topics}
                      defaultName="Themes"
                      selectedItems={topicFilters}
                      setSelectedItems={setTopicFilters}
                    />
                  </Box>
                </>
              )}

              {affects && (
                <>
                <Box sx={{ display: { xs: "block", sm: "none" } }}>
                    <MultipleCheckFilter
                      items={affects}
                      defaultName="Affects"
                      selectedItems={affectFilters}
                      setSelectedItems={setAffectFilters}
                      singleColumn
                    />
                  </Box>
                  <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    <MultipleCheckFilter
                      items={affects}
                      defaultName="Affects"
                      selectedItems={affectFilters}
                      setSelectedItems={setAffectFilters}
                    />
                  </Box>
                </>
              )}

  
  


            </InfoModalButton>
          }
        />
        {!isMapOn && (
          <ListView
            currLocation={currLocation}
            handleDeleteLocation={handleDeleteLocation}
            topicFilters={topicFilters}
            handleDeleteTopic={handleDeleteTopic}
            promptFilter={promptFilter}
            affectFilters={affectFilters}
            handleDeleteAffect={handleDeleteAffect}
            handleDeletePrompt={handleDeletePrompt}
            highlights={filteredHighlightsWithLocationFilter}
            handleMapToggle={handleMapToggle}
            locationType={MAP.label}
          />
        )}
        {isMapOn && (
          <MapView
            currLocation={currLocation}
            handleDeleteLocation={handleDeleteLocation}
            topicFilters={topicFilters}
            handleDeleteTopic={handleDeleteTopic}
            promptFilter={promptFilter}
            handleDeletePrompt={handleDeletePrompt}
            affectFilters={affectFilters}
            handleDeleteAffect={handleDeleteAffect}
            highlights={filteredHighlightsWithLocationFilter}
            handleMapToggle={handleMapToggle}
            locations={Locations}
            handleClick={handleClick}
            onClose={handleClose}
            filteredHighlightsByTopic={filteredHighlightsWithoutLocationFilter}
            locationType={MAP.label}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default MapViewContainer;
