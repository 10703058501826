import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ColoredTabs from "../../common/tabs/ColoredTabs";
import HighlightInfo from "./HighlightInfo";
import HighlightInfoCompact from "./HighlightInfoCompact";
import Highlights from "../../../assets/content/highlights_rtfc2.json";
import HeaderAndMedia from "../../common/text/HeaderAndMedia"; 

function HighlightOverviewContainer({ topics, config }) {
  const [selectedTopic, setSelectedTopic] = useState(topics[0].label);

  // Need to pass through event
  const handleChangeTab = (event, label) => {
    setSelectedTopic(label);
  };

  const handleChangeDropdown = (event) => {
    setSelectedTopic(event.target.value);
  }

  
  const highlightInfo = config.featured[selectedTopic];

  const [featured, setFeatured] = useState();
  const [bannerURL, setBannerURL] = useState(); 


  useEffect(() => {
    setFeatured(
      Highlights.filter((h) => highlightInfo.featured.includes(h.id))
    );
    const structureNoSpace = selectedTopic.replace(" ",""); 
    const structureLowerCase = structureNoSpace.toLowerCase(); 
    const bannerImageURL="/banners/" + structureLowerCase + ".png";
    console.log("Banner Image Called:" + bannerImageURL); 
    setBannerURL(
      bannerImageURL
    ); 

  }, [selectedTopic]);



  

  return (

  
    <Grid container>

  
        <Grid item xs={12}>

        <Grid
          xs={6}
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            paddingBottom: "0rem",
            marginTop: "3rem", 
            display: { xs: "none", md: "block" },
          }}
        >
          <Typography
            textAlign="center"
            variant="h5"
            sx={{ color: "Black" }}
            paddingBottom= {8}
          >

              Boston residents were invited to share their experiences through five different prompts: 
            
          </Typography>
          <Typography
            textAlign="center"
            variant="body1"
            sx={{ color: "Black" }}
            paddingBottom={0}
            > 
           

          </Typography> 
        </Grid>
      </Grid>




   
      <Grid
        item
        xs={12}
        paddingTop={0}
        marginTop={0}
        sx={{ display: { xs: "none", md: "block" } }}
      >
        <ColoredTabs
          items={topics}
          selected={selectedTopic}
          onChange={handleChangeTab}
        />
        <HighlightInfo
          selectedTopic={selectedTopic}
          highlightInfo={highlightInfo}
          highlights={featured}
          indexes={highlightInfo.featured}
          picture={bannerURL}
        />
      </Grid>
      <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
        <HighlightInfoCompact
          topics={topics}
          selectedTopic={selectedTopic}
          handleChange={handleChangeDropdown}
          highlightInfo={highlightInfo}
          highlights={featured}
          indexes={highlightInfo.featured}
        />
      </Grid>
    </Grid>
  );
}

export default HighlightOverviewContainer;
