import Grid from "@mui/material/Grid";

import Section from "../background/Section";
import Callout from "./Callout";

import { TEXTURE } from "../../../config";

function StyledCallout({ children, texture, offset, light, transparent, margin, padding }) {
  let sx = {
    color: light ? "white" : "primary.main",
  };

  if (texture) {
    sx = {
      ...sx,
      background: texture ? `url(${TEXTURE})` : "unset",
      backgroundSize: "cover",
      height: "100%",
    };
  }

  return (
    <Section margin={margin} padding={padding}>
      <Callout
        offset={offset ? "-140px" : "0px"}
        backgroundColor={
          transparent ? "transparent" : light ? "primary.dark" : "primary.light"
        }
        sx={sx}
      >
        <Grid container>{children}</Grid>
      </Callout>
    </Section>
  );
}

export default StyledCallout;
