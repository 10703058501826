import { Grid, Typography } from "@mui/material";
import StyledCallout from "../../common/callouts/StyledCallout";

import VideoAnimation from "../../../assets/about/RealTalkForChange_Animation2.mov";
import VideoThumbnail from "../../../assets/about/videothumbnail.png"; 

function IntroVideo({ }) {
  return (
    <StyledCallout offset transparent margin="0rem" padding="0rem">
      <Grid container padding={0} margin={0}>
        <video controls width ="100%" poster={VideoThumbnail}>
            <source src={VideoAnimation} type="video/mp4"/>
            Sorry, your browser doesn't support videos.
        </video> 
      </Grid>
    </StyledCallout>
  );
}

export default IntroVideo;
