import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ORGANIZATION } from "../../config";

function Footer({ extraInfo }) {
  return (
    <Grid
      container
      sx={{
        paddingTop: ".4rem",
        paddingBottom: ".4rem",
        paddingX: {xs: ".25rem", md: ".5rem"},
        background: "black",
      }}
    >

      <Grid item paddingX={2} paddingY={0.5} xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            fontSize: "13px",
            paddingRight: "1.2rem",
            color: "white"
          }}
        >
          {extraInfo}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Footer;
