import * as d3 from "d3";
import { transl, slugify, lighten, prettyCapitalize } from "./d3-utils";
import styled from "styled-components";

const EXTRA_PADDING_WHEN_ZOOMED_IN_PX = 30;

function buildTopicMap(topicMetadata) {
  return d3.index(topicMetadata, (d) => slugify(d.label));
}

function selectChildren(d, selectedID, isParentSelected = false) {
  let isSelected = d.id === selectedID || (d.id === "::" && !selectedID);
  let children = d.children
    .map((c) => selectChildren(c, selectedID, isSelected))
    .filter((c) => !!c);

  if (isSelected) {
    return {
      ...d,
      children: children,
      isTerminal: d.children.length <= 0,
      count: d.cumulativeCount,
    };
  } else if (isParentSelected) {
    return {
      ...d,
      children: [],
      isTerminal: d.children.length <= 0,
      count: d.cumulativeCount,
    };
  } else {
    return children?.[0];
  }
}

function bakePack(
  data,
  width,
  height,
  circlePadding,
  selectedID,
  usebuckets,
  topicMetadata
) {
  let dataWithbuckets = data.map((d) => {
    return { bucket: "", ...d };
  });

  let subTopicData = d3
    .flatRollup(
      dataWithbuckets,
      (v) => v.length,
      (d) => d.bucket,
      (d) => d.topic,
      (d) => d.subTopic
    )
    .map(([bucket, topic, subTopic, count]) => {
      let themeSlug = slugify(topic);
      let meta = topicMetadata.get(themeSlug);
      let primaryColor = meta?.primaryColor || meta?.color;

      let highlightIds = data
        .filter((d) => d.subTopic === subTopic)
        .map((d) => d.highlight_id);
      return {
        id: slugify(bucket) + "::" + themeSlug + "::" + slugify(subTopic),
        label: prettyCapitalize(subTopic),
        subTopic: subTopic,
        topic: topic,
        bucket: bucket,
        count: count,
        highlightCount: count,
        highlightIds: new Set(highlightIds),
        children: [],
        primaryColor: primaryColor,
        secondaryColor: meta?.secondaryColor || lighten(primaryColor),
      };
    });

  let topicData = d3
    .flatGroup(
      subTopicData,
      (d) => d.bucket,
      (d) => d.topic
    )
    .map(([bucket, topic, subTopics]) => {
      let themeSlug = slugify(topic);
      let meta = topicMetadata.get(themeSlug);
      let primaryColor = meta?.primaryColor || meta?.color;

      let highlightIds = new Set(subTopics.flatMap((d) => [...d.highlightIds]));
      return {
        id: slugify(bucket) + "::" + themeSlug,
        label: prettyCapitalize(topic),
        topic: topic,
        bucket: bucket,
        count: 0,
        highlightCount: highlightIds.size,
        children: subTopics,
        primaryColor: primaryColor,
        secondaryColor: meta?.secondaryColor || lighten(primaryColor),
      };
    });

  let bucketData = d3
    .flatGroup(topicData, (d) => d.bucket)
    .map(([bucket, topics]) => {
      return {
        id: slugify(bucket),
        label: prettyCapitalize(bucket),
        bucket: bucket,
        count: 0,
        children: topics,
      };
    });

  let root = usebuckets ? { children: bucketData } : bucketData[0];
  root.id = "::";

  function assignCumulativeCount(d) {
    let children = d.children.map(assignCumulativeCount);
    let count = children.reduce((sum, d) => sum + d.cumulativeCount, 0);
    return {
      ...d,
      children: children,
      cumulativeCount: count + d.count,
    };
  }

  root = assignCumulativeCount(root);
  root = selectChildren(root, selectedID);

  let hierarchy = d3
    .hierarchy(root)
    .sum((d) => d.count)
    .sort((a, b) => b.value - a.value);

  return d3.pack().padding(circlePadding).size([width, height])(hierarchy);
}

export default class PackedCirclesViz {
  constructor(
    svgRef,
    width,
    height,
    {
      usebuckets = false,
      circlePadding = 4, // Spacing between circles
      animDuration = 150, // Length of update transition in ms
    } = {}
  ) {
    this.svgRef = svgRef;
    this.base = null;
    this.width = width;
    this.height = height;
    this.usebuckets = usebuckets;
    this.circlePadding = circlePadding;
    this.animDuration = animDuration;
  }

  setUp(x, y) {
    console.assert(this.svgRef.current !== null);

    let svg = d3.select(this.svgRef.current);
    this.base = svg
      .append("g")
      .classed("base", true)
      .attr("transform", transl(x, y));
  }

  tearDown() {
    console.assert(this.svgRef.current !== null);
    d3.select(this.svgRef.current).selectChildren().remove();
  }

  update(data, topicMetadata, selected, section) {
    if (!this.base) {
      console.error("You must run setUp() before calling update().");
      return;
    }

    topicMetadata = buildTopicMap(topicMetadata);

    let pack = bakePack(
      data,
      this.width,
      selected
        ? this.height - EXTRA_PADDING_WHEN_ZOOMED_IN_PX * 2
        : this.height,
      this.circlePadding,
      selected?.id,
      this.usebuckets,
      topicMetadata
    );

    let zoomTrans = d3
      .transition()
      .duration(this.animDuration)
      .ease(d3.easeBackInOut);
    let opacityTrans = d3
      .transition()
      .duration(this.animDuration)
      .ease(d3.easeExpIn);

    let move = selected
      ? (d) => transl(d.x, d.y + EXTRA_PADDING_WHEN_ZOOMED_IN_PX)
      : (d) => transl(d.x, d.y);
    let nodes = this.base
      .selectAll("g.node")
      .data(
        pack.descendants().filter((n) => n.data.id !== "::"),
        (n) => n.data.id
      )
      .join(
        (enter) => {
          let g = enter.append("g").attr("transform", move).attr("opacity", 0);

          g.transition(opacityTrans).attr("opacity", 1);

          g.append("circle")
            .attr("fill", (d) => {
              return d.data.primaryColor;
            })
            .attr("r", (d) => d.r);

          const textGroup = g.append("g");
          textGroup.append("text");

          return g;
        },
        (update) => {
          update.transition(zoomTrans).attr("transform", move);

          update
            .select("circle")
            .transition(zoomTrans)
            .attr("r", (d) => d.r);

          return update;
        }
      )
      .classed("node", true);

    nodes.classed("selected", (n) => selected?.id.startsWith(n.data.id));
    nodes.classed("selectable", (n) => !n.data.isTerminal);
    nodes.classed(
      "terminal",
      (n) => n.data.isTerminal && n.data.label !== section
    );
    nodes.classed("section", (n) => n.data.label === section);

    nodes
      .select("text")
      .selectAll("tspan")
      .data((d) => {
        if (d.data.isTerminal) {
          return d.data.label
            .split(" ")
            .concat([` (${d.data.highlightCount} highlights)`]);
        }

        return d.data.label.split(" ");
      })
      .join("tspan")
      .attr("x", 0)
      .attr("y", (d, i, nodes) => `${(i - nodes.length / 2) * 1.3 + 1}em`)
      .text((d) => d);
  }

  registerHandlers(selected, setSelected) {
    this.base.selectAll("g.node.selectable").on("click", function (e, n) {
      if (n.data.id === selected?.id) setSelected(null, null);
      else {
        setSelected(n.data, null);
      }
    });
  }

  deregisterHandlers() {
    this.base.selectAll("g.node.selectable").on(".", null);
  }

  static styled(colors) {
    let c = {
      darkLabelFill: "#fff",
      lightLabelFill: "#eee",
      unselectedCircleFill: "#285F74",
      selectedCircleFill: "#469FAD",
      selectedCircleStroke: "#00a679",
      ...colors,
    };

    return styled.svg`
      width: auto;
      max-height: 60vh;

      text {
        text-anchor: middle;
        font-size: 12px;
        pointer-events: none;
      }

      g.node.selectable circle {
        cursor: pointer;
      }

      g.node.selectable:hover circle {
        cursor: pointer;
      }

      g.node.terminal circle {
        stroke-width: 0px;
        fill: #fff;
      }

      g.node.terminal text {
        fill: #000;
      }

      g.node.selectable text {
        fill: #fff;
      }

      g.node.selected text {
        display: none;
      }

      g.node.selected,
      g.node.terminal text {
        display: block;
      }
    `;
  }
}
