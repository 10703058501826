import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import InteractiveMap from "./InteractiveMap";
import HighlightScroll from "./HighlightScroll";


import MapBackground from "../../../assets/textures/bostonmaptexture.png";


const styles = {
  rtfcMapBackground: {
      backgroundImage: `url(${MapBackground})`
  }
};

function MapView({
  currLocation,
  handleDeleteLocation,
  topicFilters,
  handleDeleteTopic,
  promptFilter,
  handleDeletePrompt,
  affectFilters, 
  handleDeleteAffect, 
  highlights,
  handleMapToggle,
  locations,
  handleClick,
  onClose,
  filteredHighlightsByTopic,
  locationType,
}) {
  return (
    <>
      <Grid item lg={5} display={{ xs: "none", md: "block" }}>
        <HighlightScroll
          currLocation={currLocation}
          handleDeleteLocation={handleDeleteLocation}
          topicFilters={topicFilters}
          handleDeleteTopic={handleDeleteTopic}
          promptFilter={promptFilter}
          handleDeletePrompt={handleDeletePrompt}
          affectFilters={affectFilters}
          handleDeleteAffect={handleDeleteAffect}
          highlights={highlights}
          sectionId="full"
          isMapShowing={true}
          handleMapToggle={handleMapToggle}
          locationType={locationType}
        />
      </Grid>
      <Grid style={styles.rtfcMapBackground} item xs={12} lg={7} sx={{ paddingTop: "3rem" }}>
        <Grid
          item
          paddingRight={{ xs: 4, md: 5 }}
          sx={{
            display: "flex",
            justifyContent: "right",
            paddingBottom: "1rem",
          }}
        >
          <Button onClick={handleMapToggle} size="small">
            Hide Map{" "}
            <ArrowForwardIosIcon
              sx={{
                fontSize: "12px",
                verticalAlign: "middle",
                paddingLeft: ".5rem",
              }}
            />
          </Button>
        </Grid>
        <Grid sx={{ display: { xs: "none", md: "block" } }}>
          <InteractiveMap
            currLocation={currLocation}
            locations={locations}
            highlights={highlights}
            onClick={handleClick}
            onClose={onClose}
            filteredHighlightsByTopic={filteredHighlightsByTopic}
            locationType={locationType}
          />
        </Grid>
        <Grid paddingBottom={3} sx={{ display: { xs: "block", md: "none" } }}>
          <InteractiveMap
            currLocation={currLocation}
            locations={locations}
            highlights={highlights}
            onClick={handleClick}
            onClose={onClose}
            filteredHighlightsByTopic={filteredHighlightsByTopic}
            locationType={locationType}
            noHeight
          />
        </Grid>
      </Grid>
      <Grid item xs={12} display={{ xs: "block", md: "none" }}>
        <HighlightScroll
          currLocation={currLocation}
          topicFilters={topicFilters}
          handleDeleteTopic={handleDeleteTopic}
          promptFilter={promptFilter}
          handleDeletePrompt={handleDeletePrompt}
          affectFilters={affectFilters}
          handleDeleteAffect={handleDeleteAffect}
          highlights={highlights}
          sectionId="compact"
          isMapShowing={true}
          handleMapToggle={handleMapToggle}
          locationType={locationType}
        />
      </Grid>
    </>
  );
}

export default MapView;
