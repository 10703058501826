import CommunityLife from "./communitylife.mp3";
import Inequality from "./inequality.mp3";
import Economic from "./economicopportunity.mp3";
import Education from "./education.mp3";
import GovernmentAndInstitutions from "./governmentandinstitutions.mp3";
import Housing from "./housing.mp3";
import Infrastructure from "./infrastructure.mp3";
import PublicHealth from "./publichealth.mp3";
import Safety from "./safety.mp3";

export default [
  {
    title: "Community Life",
    audioSrc: CommunityLife,
    time: "1:25",
    numVoices: 4,
  },
  {
    title: "Inequality",
    audioSrc: Inequality,
    time: "1:16",
    numVoices: 5,
  },
  {
    title: "Economic Opportunity",
    audioSrc: Economic,
    time: "1:23",
    numVoices: 4,
  },
  {
    title: "Education",
    audioSrc: Education,
    time: "1:29",
    numVoices: 5,
  },
  {
    title: "Government And Institutions",
    audioSrc: GovernmentAndInstitutions,
    time: "1:28",
    numVoices: 4,
  },
  {
    title: "Housing",
    audioSrc: Housing,
    time: "1:39",
    numVoices: 6,
  },
  {
    title: "Infrastructure",
    audioSrc: Infrastructure,
    time: "1:18",
    numVoices: 4,
  },
  {
    title: "Public Health",
    audioSrc: PublicHealth,
    time: "1:53",
    numVoices: 7,
  },
  {
    title: "Safety",
    audioSrc: Safety,
    time: "1:23",
    numVoices: 3,
  }
];