import { Grid, Container } from "@mui/material";
import { TEXTURE } from "../../../config";

const Section = ({ children, texture, dark, margin, padding, hide }) => {
  return (
    <Grid
      container
      item
      paddingBottom={padding ? padding: 6}
      marginBottom={margin ? padding : 11}
      xs={12}
      sx={{
        height: "100%",
        backgroundImage: texture ? `url(${TEXTURE})` : "unset",
        backgroundSize: "cover",
        paddingBottom: padding ? padding : "2rem", 
        backgroundColor: dark ? "primary.light" : "unset",
        display: hide ? "none" : "inline-block"
      }}
    >
      <Container maxWidth="lg">{children}</Container>
    </Grid>
  );
};

export default Section;
