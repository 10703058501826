import { useEffect } from "react";
import { Container, Grid } from "@mui/material";

import InfoCardAbout from "./InfoCardAbout";
import StyledBanner from "../banners/StyledBanner";

import AboutBanner from "../../../assets/banners/aboutusbanner2.png"; 

function InfoCardView({ config }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <Grid container paddingBottom={6}>
      <StyledBanner
        title={config.title}
        body={config.description}
        picture={AboutBanner}
        texture
        light
      />

      <Container maxWidth="lg">
        <Grid
          container
          item
          spacing={5}
          paddingX={{ xs: 3, md: 0 }}
          paddingY={10}
        >
          {config.cards.map((card) => (
            <Grid xs={12} sm={12} md={4} item>
              <InfoCardAbout
                link={card.link}
                title={card.title}
                linkLabel={card.button}
                picture={card.picture}
              >
                {console.log("CARD LINK: " + card.link)}
                <p>{card.body}</p>
              </InfoCardAbout>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Grid>
  );
}

export default InfoCardView;
