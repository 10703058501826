import { useState } from "react";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LinkButton from "../buttons/LinkButton";
import LinkButtonExternal from "../buttons/LinkButtonExternal"
import ZagatEmbed from "../../Audio/ZagatEmbed";
import CommunityAffectBar from "../../TopicView/CommunityAffectBar";
import SummaryMedleyWrapper from "../../Audio/Medley/SummaryMedleyWrapper"; 

import JsxParser from "react-jsx-parser";

export default function InfoCard({
  title,
  subTitle,
  picture,
  link,
  link2,
  dimensionLinks,
  linkLabel,
  link2Label, 
  children,
  small,
  preview,
  transparent,
  light,
  hideSubtitle,
  expandedText,
  getEmbedInfo,
  subTitleList,
  stackedBarData,
  external,
  icon, 
  medley,
  color, 
  secondaryColor, 
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: transparent ? "unset" : "white",
      }}
    >
      {picture && <CardMedia sx={{ paddingTop: "40%" }} image={picture} />}


      <CardContent sx={{ padding: "1.5rem" }}>

        <Grid container wrap="nowrap" spacing={2}>
          {icon && 
            <Grid item> 
            <img src={icon} width="40px" /> 
            </Grid> 
          }
          <Grid item xs> 
            <Typography
              variant="h5"
              component="div"
              marginBottom={subTitle ? 0 : 2}
              sx={{
                color: light ? "white" : "#333333",
                paddingBottom: ".5rem",
              }}
            >
              {title}
            </Typography>
          </Grid> 
        </Grid> 

        {subTitle && !hideSubtitle && (
          <Typography
            gutterBottom
            paddingBottom={preview ? 2 : 0}
            variant={small ? "body2" : "body1"}
            component="div"
            color={light ? "white" : "#333333"}
          >
            <div style={{ paddingTop: "1rem" }}>
              Emergent Themes:
            </div>
            {!subTitleList && subTitle}
            {subTitleList && (
              <ul style={{ paddingLeft: "1rem" }}>
                {subTitleList.map((item) => (
                  <li>{item}</li>
                ))}
              </ul>
            )}
          </Typography>
        )}

        {stackedBarData && stackedBarData.length > 0 && (
          <CommunityAffectBar data={stackedBarData}></CommunityAffectBar>
        )}  

        {medley && (

        <SummaryMedleyWrapper
              selectedTheme={title}
              medleyByTheme={medley}
              primaryColor={color}
              secondaryColor={secondaryColor}
        />
        )}

        <Typography
          variant="body2"
          color={light ? "whitesmoke" : "#333333"}
          paddingTop={hideSubtitle ? 0.5 : 0}
      >
         
          {preview ? (
            <JsxParser
              bindings={{ getEmbedInfo }}
              components={{ Embed: ZagatEmbed }}
              jsx={preview}
            />
          ) : (
            children
          )}
        </Typography>
        {expandedText && !isExpanded && (
          <Typography
            component="div"
            sx={{
              marginTop: 2,
              marginBottom: 1,
              color: "primary.main",
              cursor: "pointer",
            }}
            variant="caption"
            onClick={toggleExpanded}
          >
            <strong>Read More</strong>
          </Typography>
        )}
        {expandedText && isExpanded && (
          <>
            <Typography
              variant="body2"
              color={light ? "whitesmoke" : "text.secondary"}
            >
              <JsxParser
                bindings={{ getEmbedInfo }}
                components={{ Embed: ZagatEmbed }}
                jsx={expandedText}
              />
            </Typography>
            <Typography
              component="div"
              sx={{
                marginTop: 2,
                marginBottom: 1,
                color: "primary.main",
                cursor: "pointer",
              }}
              variant="caption"
              onClick={toggleExpanded}
            >
              <strong>Read Less</strong>
            </Typography>
          </>
        )}
      </CardContent>
      {link2 && !external && (
        <CardActions sx={{ paddingX: "1.5rem" }}>
          <LinkButton link={link2} size={small ? "small" : "medium"}>
            {link2Label}
          </LinkButton>
        </CardActions>
      )}
      {link && !external && (
        <CardActions sx={{ paddingX: "1.5rem", paddingBottom: "1.5rem" }}>
          <LinkButton link={link} size={small ? "small" : "medium"}>
            {linkLabel}
          </LinkButton>
        </CardActions>
      )}

      {link2 && external && (
        <CardActions sx={{ paddingX: "1.5rem" }}>
          <LinkButtonExternal link={link2} size={small ? "small" : "medium"}>
            {link2Label}
          </LinkButtonExternal>
        </CardActions>
      )}
      {link && external && (
        <CardActions sx={{ paddingX: "1.5rem", paddingBottom: "1.5rem" }}>
          <LinkButtonExternal link={link} size={small ? "small" : "medium"}>
            {linkLabel}
          </LinkButtonExternal>
        </CardActions>
      )}


     
      {dimensionLinks && (
        <Grid container xs={12} paddingX={3} paddingBottom={5}>
          <Grid item xs={12}>
            <Typography
              component="div"
              variant="body2"
              color={light ? "whitesmoke" : "text.secondary"}
              paddingTop={2}
              paddingBottom={1.5}
            >
              <b>Highlights on {title}:</b>
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            {dimensionLinks.map((dimension) => (
              <Grid item paddingRight={1.5}>
                <LinkButton
                  link={dimension.link}
                  size={small ? "medium" : "medium"}
                >
                  {dimension.label}
                </LinkButton>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Card>
  );
}


/*


import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LinkButton from "../buttons/LinkButton";

import JsxParser from "react-jsx-parser";

export default function InfoCard({
  title,
  subTitle,
  picture,
  link,
  linkLabel,
  children,
  small,
  preview,
  transparent,
  light,
}) {
  return (
    <Card
      elevation={0}
      sx={{ backgroundColor: transparent ? "unset" : "white" }}
    >
      {picture && <CardMedia sx={{ paddingTop: "40%" }} image={picture} />}
      <CardContent sx={{ padding: "1.5rem" }}>
        <Typography
          variant="h5"
          component="div"
          sx={{
            color: light ? "white" : "primary.dark",
            paddingBottom: ".5rem",
          }}
        >
          {title}
        </Typography>
        {subTitle && (
          <Typography
            gutterBottom
            variant={small ? "body2" : "body1"}
            component="div"
            color={light ? "white" : "primary.dark"}
            sx={{ fontWeight: "550" }}
          >
            {subTitle}
          </Typography>
        )}
        <Typography
          variant="body2"
          color={light ? "whitesmoke" : "text.secondary"}
          sx={{ paddingTop: "1rem" }}
        >
          {preview ? <JsxParser jsx={preview} /> : children}
        </Typography>
      </CardContent>
      {link && (
        <CardActions sx={{ padding: "1.5rem" }}>
          <LinkButton link={link} size={small ? "small" : "medium"}>
            {linkLabel}
          </LinkButton>
        </CardActions>
      )}
    </Card>
  );
}
*/
