import * as d3 from "d3";
import SimpleColorConverter from "simple-color-converter";

import { useLocation } from "react-router-dom";
import { MAP } from "../config";

export const calculateHighlightCountByLocation = (speakers, highlights) => {
  const countByLocation = {};

  highlights.forEach((h) => {
    const foundSpeaker = {};
    let location = getSpeaker(speakers, h)?.demographics[
      MAP.demographicId
    ].toLowerCase();

    // TODO: Change to check list in new pipeline format
    if (location) {
      if (!foundSpeaker[location]) {
        if (!countByLocation[location]) countByLocation[location] = 0;
        countByLocation[location]++;
      }
      foundSpeaker[location] = true;
    }
  });

  return countByLocation;
};

/**
 * Valid query args for the map view.
 */
export const MAP_QUERY_ARGS = {
  location: "location",
  hideMap: "hideMap",
  topic: "topic",
  prompt: "prompt",
  affect: "affect"
};

/**
 * Convenience function for getting query args.
 **/
export const useQueryArg = (param) => {
  const queryArg = new URLSearchParams(useLocation().search).get(param);
  return queryArg;
};

const padTimeLeft = (string, pad, length) => {
  return (new Array(length + 1).join(pad) + string).slice(-length);
};

/**
 * Returns a formatted time in MM:SS.
 */
export const formatDuration = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainder = Math.round(seconds - minutes * 60.0);
  return `${padTimeLeft(minutes, "0", 2)}:${padTimeLeft(remainder, "0", 2)}`;
};

/**
 * Returns the speaker for a highlight
 */
export const getSpeaker = (speakers, highlight) => {
  const { conversation_id, speaker } = highlight;
  return speakers.find(
    (s) => s.conversation_id === conversation_id && speaker === s.name
  );
};

/**
 * Returns the speaker image source or undefined.
 */
export const getSpeakerImgSrc = (speakers, highlight) => {
  const speaker = getSpeaker(speakers, highlight);
  let src;
  if (speaker) {
    src = `${process.env.PUBLIC_URL}/avatars/${getSpeakerPath(
      speaker.name
    )}.jpg`;
  }

  return src;
};

export const getSpeakerPath = (speakerName) => {
  return speakerName.replaceAll(" ", "-");
};

/**
 * Returns an array of the speaker locations. The first element
 * is the primary location.
 */
export const getSpeakerInfo = (demographic, speakers, highlight) => {
  const speaker = getSpeaker(speakers, highlight);
  if (speaker) {
    const { demographics } = speaker;

    // TODO: New speaker pipeline returns an array.
    // Use .join(", ") in the future and return "Undisclosed" when not found
    const info = demographics[demographic];
    if (info) {
      return getCamelCase(info);
    }
  }
  return null;
};

export const getCamelCase = (str) => {
  // TODO: Remove .toString() when convert to new pipeline format
  const parts = str.toString().split(" ");
  return parts.map((part) => part[0].toUpperCase() + part.slice(1)).join(" ");
};

export const getParentTagFromTagString = (tag) => {
  const parts = tag.split(" :: ");
  return parts[0].trim();
};

export const isColorLight = (hexColor) => {
  // determine if the color is light or dark
  // from https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
  const red = parseInt(`${hexColor[1]}${hexColor[2]}`, 16);
  const green = parseInt(`${hexColor[3]}${hexColor[4]}`, 16);
  const blue = parseInt(`${hexColor[5]}${hexColor[6]}`, 16);
  return red * 0.299 + green * 0.587 + blue * 0.114 > 186;
};

export function muteColor(color, grayscale) {
  let c = d3.hsl(color);

  if (grayscale) {
    const bwColor = new SimpleColorConverter({
      hsl: c,
      to: "hex",
      grayscale: grayscale,
    });
    return `#${bwColor.color}`;
  } else {
    return c.copy({ s: 0.5, l: 0.8 }).formatHex();
  }
}

// get the totals for community affects
export const getCommunityAffectTotals = (communityAffects) => {
  const totals = {};

  // add up the counts for each community affect in the object
  for (const key in communityAffects) {
    const theme = communityAffects[key];
    theme.forEach((ca) => {
      const { name, count } = ca;
      if (!totals[name]) totals[name] = 0;
      totals[name] += count;
    });
  }
  // return as an array of name and count
  return Object.keys(totals).map((name) => {
    return { name, count: totals[name] };
  });
};

// enrich the community affect data positive and negative and totals
export const enrichCommunityAffectData = (totals, affectMetaData) => {
  const positiveAffects = affectMetaData
    .filter((a) => a.positive)
    .map((a) => a.label);
  const negativeAffects = affectMetaData
    .filter((a) => !a.positive)
    .map((a) => a.label);

  // removed "untagged" from totals
  totals = totals.filter((t) => t.name !== "untagged");

  // for each affect in the total determine if it's positive or negative and get the totals
  totals.forEach((t) => {
    const { name } = t;
    t.positive = positiveAffects.includes(name);
    t.negative = negativeAffects.includes(name);
  });

  // add additional metadata to the totals
  totals.forEach((t) => {
    const { name } = t;
    const affect = affectMetaData.find((a) => a.label === name);
    t.color = affect.color;
    t.description = affect.description;
  });

  // initialize the summary object
  const summary = { positiveTotal: 0, negativeTotal: 0, total: 0 };
  totals.forEach((t) => {
    const { count } = t;
    if (t.positive) summary.positiveTotal += count;
    if (t.negative) summary.negativeTotal += count;
    summary.total += count;
  });

  return { totals, summary };
};


//track Summary Medley Play
export const track = (category, label) => {
  window.gtag("event", category, {
    label,
  });
};

export const trackSummaryMedleyPlay = (theme) => {
  track("portal.theme-explorer.theme.medley.played", theme);
};


/**
 * Get theme string from ThemeViz.
 */
 export function getThemeString(themes, themeID) {
  if (!themeID || themeID === "::") return "Overview";
  let theme = themes.find((e) => makeID(e.title) === themeID);
  return theme.title.replace(/\b(\w)/g, (s) => s.toUpperCase());
}

export function isSubthemeString(themeID) {
  return themeID.split("::").length === 3;
}

export const BREAK = "<br/>";
export function slugify(s) {
  return s.replaceAll(BREAK, "").toLowerCase().replaceAll(/\s+/g, "-");
}

export function makeID(s) {
  return "::" + slugify(s);
}
