import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, {useEffect } from 'react';

import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';

import { Box } from "@mui/system";

import Landing from "./components/Landing/Landing";
import NavBar from "./components/NavBar";
import MapViewContainer from "./components/VoicesView/MapView/MapViewContainer";
import TopicViewContainer from "./components/TopicView/TopicViewContainer";
import ConversationViewContainer from "./components/VoicesView/ConversationView/ConversationViewContainer";
import Insights from "./components/TopicView/Insights";
import Voices from "./components/VoicesView/Voices";
import About from "./components/About/About";
import Process from "./components/About/Process/Process";
import Team from "./components/About/Team/Team";
import Media from "./components/About/Media/Media";

import Highlights from "./assets/content/highlights_rtfc2.json";
import Topics from "./assets/content/topics-rtfc.json";
import Prompts from "./assets/content/prompts-rtfc2.json";
import Affects from "./assets/content/affects-rtfc.json"; 


const TRACKING_ID = "G-SGVLCS7XWD";
ReactGA.initialize(TRACKING_ID);


function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  return (
    <HelmetProvider>
    <div id="app">
      <Box minHeight="100vh">
        <NavBar topics={Topics} />
          <Routes>
            <Route path="/" element={<Landing topics={Topics} prompts={Prompts} highlights={Highlights} />} />
            <Route path="/insights" element={<Insights topics={Topics} />} />
            <Route path="insights">
              <Route
                path=":id"
                element={
                  <TopicViewContainer topics={Topics} highlights={Highlights} />
                }
              />
            </Route>
            <Route path="/voices" element={<Voices topics={Topics} />} />
            <Route
              path="voices/map-explorer"
              element={
                <MapViewContainer topics={Topics} prompts={Prompts} highlights={Highlights} affects={Affects} />
              }
            />
            <Route
              path="voices/conversation-explorer"
              element={<ConversationViewContainer highlights={Highlights} />}
            />
            <Route path="about" element={<About />} />
            <Route path="about/team" element={<Team />} />
            <Route path="about/process" element={<Process />} />
            <Route path="about/media" element={<Media />} />
          </Routes>
      </Box>
    </div>
    </HelmetProvider>
  );
}

export default App;
