



import { useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import TopicsViz from "./TopicsViz";
import DimensionCard from "../../TopicView/DimensionCard"; 
import ConversationModal from "../../VoicesView/ConversationView/ConversationModal";
import TopicCard from "../../TopicView/TopicCard";
import InfoCardTopicView from "../../common/cards/InfoCardTopicView";
import HeaderAndMedia from "../../common/text/HeaderAndMedia"; 

import { extractTopic } from "./PackedCircles/d3-utils.js";
import DefaultImage from "../../../assets/textures/topiccardimage2.png";

// import community affects
import CommunityAffectData from "../../../assets/content/community_affect_counts.json";
import { getCommunityAffectTotals } from "../../../utils/utils";

import { OVERVIEW } from "../../TopicView/config";
import Medleys from "../../../assets/medleys/medleys"; 

import {
  CTA,
} from "../config/config";


function TopicsOverviewContainer({ topics, config, margin, highlights }) {
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedSubTopic, setSelectedSubTopic] = useState(null);
  const [selectedEmbedId, setSelectedEmbedId] = useState(null);

  const handlePopupClose = () => {
    setSelectedEmbedId(null);
  };


  const handleSelection = (topic, subTopic) => {
    setSelectedTopic(topic); //set null to something to select subtopic (was previously null)
    // set null to something to select subtopic (was previously null)
    setSelectedSubTopic(subTopic ? subTopic : null);
  };

  const handleClose = () => {
    setSelectedTopic(null);
    setSelectedSubTopic(null);
  };

  const topicId = selectedTopic ? extractTopic(selectedTopic) : null; // key for showing subtopic info // this doesn't change when subtopic are selected
  const topicInfo = topics.find((topic) => topic.id === topicId);
  const stackedBarData = topicId
    ? CommunityAffectData[topicId.split("-").join(" ")]
    : getCommunityAffectTotals(CommunityAffectData);


  const getEmbedInfo = () => {
    return {
      selectedEmbedId: parseInt(selectedEmbedId),
      setSelectedEmbedId,
      primaryColor: topicInfo.primaryColor,
      secondaryColor: topicInfo.secondaryColor,
      popUp: true,
      setOpen: setSelectedEmbedId,
    };
  };


  var topicImgURL=OVERVIEW.banners.default.src; 
  var iconImgURL = null; 
  var topicMedley = null; 
  

  if (topicInfo) {
    topicImgURL= OVERVIEW.banners[topicId]?.src2; 
    iconImgURL = OVERVIEW.banners[topicId]?.icon; 
    topicMedley = OVERVIEW.banners[topicId]?.medley; 
  } 

  const styles = {
    mapBackground: {
      backgroundImage: `url(${topicImgURL})`,
    },
  };

  console.log(topicMedley); 





  return (


<>

{selectedEmbedId && (
  <ConversationModal
    onClose={handlePopupClose}
    highlight={highlights.find((h) => h.id === parseInt(selectedEmbedId))}
  />
)}
<Grid
  container
  sx={{ border: "1px solid #E6E7E7", backgroundColor: "white" }}
>

  <Grid item
        xs={12}
        md={6.25}
        paddingTop={{ xs: 3, md: 0 }}
        paddingBottom={5}
        style={styles.mapBackground}>

    <center>
      <TopicsViz
        themeMetadata={topics}
        selected={selectedTopic}
        section={selectedSubTopic}
        onSelection={handleSelection}
        onClose={handleClose}
        margin={margin}
      />
    </center>
  </Grid>
  <Grid item xs={12} md={5.75} sx={{ backgroundColor: "white" }}>
    <Box paddingTop={6} paddingBottom={8} paddingX={{ xs: 3, sm: 6 }}>
      {topicId && (
        <DimensionCard
          dimensionInfo={topicInfo}
          transparent
          search
          expandedText={topicInfo.preview}
          getEmbedInfo={getEmbedInfo}
          expandedOnly
          linkLabel="Explore Full Insight"
          hideSubtitle
          stackedBarData = {stackedBarData}
          icon = {iconImgURL}
          medley = {Medleys}
        />
        
      )}
      {!topicId && (
        <InfoCardTopicView
          title={config.title}
          link={config.link}
          linkLabel={config.linkLabel}
          transparent
          dark
          stackedBarData={stackedBarData}
          icon = {iconImgURL}
        >
        {config.body.map((paragraph, idx) => (
          <p key={`${config.title}-${idx}`}>{paragraph}</p>
        ))}
        </InfoCardTopicView>
      )}
    </Box>
  </Grid>
</Grid>
</>
);
}

export default TopicsOverviewContainer;
