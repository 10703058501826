import InfoCard from "../../common/cards/InfoCard";
import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card"; 
import CardMedia from "@mui/material/CardMedia"; 




function LocationCard({ locationInfo, transparent, light }) {


  const locationTitle = locationInfo.title.replace(" ",""); 
  const locationTitleLower = locationTitle.toLowerCase(); 
  console.log(locationTitleLower); 
  var locationImageLink; 

  if (locationTitleLower==="listento voices by neighborhood")
  {
    locationImageLink="/chatillustration.png"
  } else {
    locationImageLink = "/" + locationTitleLower + ".jpeg"; 
  }

  const locationAlt = "Picture from " + locationInfo.title; 
  console.log(locationImageLink); 

  const styles = {
    Chinatown: {
      backgroundImage: `url(${'/public'})`,
    },

  };


  
  return (
    <InfoCard
      title={locationInfo.title}
      link={locationInfo.link}
      linkLabel={`Explore ${locationInfo.label}`}
      transparent={transparent}
      light={light}
    >

      
      {locationInfo.participants && (<p> Over <strong> {locationInfo.participants} Boston residents participated in {locationInfo.conversations} unique conversations. </strong>  Explore their stories by neighborhood using the interactive map. </p> )}
      {locationInfo.tagline && <p> {locationInfo.tagline} </p>}
      {
        <img 
          src={locationImageLink} 
          alt={locationAlt}
          width="350px"
          height= "300px"
          object-fit = "contain"
        />} 

      {locationInfo.tagline && <p> (Summary and images from <a href="https://www.boston.gov/neighborhoods">Boston.gov</a>) </p> }
          
    </InfoCard>
  );
}

export default LocationCard;
