import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import Section from "../../common/background/Section";

import CenterHeader from "../../common/text/CenterHeader";
import ProfileGrid from "./ProfileGrid";



function StaffCallout({ config }) {
  return (
    <>
      
    <Section> 
    <Grid item xs={12}>
      <Paper
        sx={{
          color: "black", 
          //background: `url(${AboutBanner})`,
          backgroundSize: "cover",
          height: "100%",
          backgroundColor: "#FCF7F3",
          marginTop: "-140px",
        }}
        elevation={0}
      >
        <Box
          marginY={{ xs: 2, md: 7 }}
          marginX={{ xs: 3, md: 6 }}
          paddingY={{ xs: 0, md: 0}}
        >

          <CenterHeader title={config.title} body={config.body} />
          <ProfileGrid config={config.people} />
      
        </Box>
      </Paper>
    </Grid>
    </Section>
      
    </>
  );
}

export default StaffCallout;
