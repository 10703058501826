import React from "react";
import { Box } from "@mui/material";

export function BarSegment({ decimal, color, right }) {
  return (
    <Box
      style={{
        width: `${decimal * 100}%`,
        height: "100%",
        backgroundColor: color,
        float: right ? "right" : "left",
      }}
    />
  );
}
