import InfoCardTopicView from "../common/cards/InfoCardTopicView";

export default function TopicCard({
  topicInfo,
  topicId,
  picture,
  teaserHtml,
  small,
  transparent,
  light,
  stackedBarData, 
  icon, 
}) {
  const preview = teaserHtml
    ? topicInfo.preview.concat(teaserHtml)
    : topicInfo.preview;

  return (
    <InfoCardTopicView
      title={topicInfo.label}
      subTitle={topicInfo.subTopics
        .filter((subTopic) => subTopic.label !== "Overview")
        .map((subTopic) => subTopic.label)
        .join(", ")}
      color={topicInfo.primaryColor}
      link={`/insights/${topicId}`}
      linkLabel={`View ${topicInfo.label}`}
      picture={picture}
      small={small}
      preview=""
      transparent={transparent}
      light={light}
      stackedBarData={stackedBarData}
      icon={icon}
    />
  );
}
