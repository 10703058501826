import React from "react";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { getCamelCase } from "../../utils/utils";
import { Grid, Typography } from "@mui/material";
import { Percent } from "./Percent";
import { BarSegment } from "./BarSegment";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.dark,
    border: "1px solid #dadde9",
    maxWidth: 450,
  },
}));

function Bar({ decimal, data }) {
  return (
    <>
      <BarSegment decimal={decimal} color={data.color} />
      <BarSegment decimal={1 - decimal} color="#E5E5E5" right />
    </>
  );
}

export default function BarTooltip({ data, percent, children }) {
  return (
    <HtmlTooltip
      title={
        <>
          <Grid container p={2} spacing={1} sx={{minWidth: 400}}>
            <Percent decimal={percent / 100} positive={data.positive} />
            <Grid item xs={5}> 
               <Typography>{getCamelCase(data.name)}</Typography>
            </Grid> 

            <Grid item xs={5}> 
              <Bar decimal={percent / 100} data={data} />
            </Grid> 
          
            <Grid item xs={12}>
              <Typography>{data.description}</Typography>
            </Grid>
            
          </Grid>
        </>
      }
      placement="top"
    >
      {children}
    </HtmlTooltip>
  );
}
