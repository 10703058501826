import { HighlightAlt } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import InfoModalButton from "../../common/buttons/InfoModalButton";

import { InfoOutlined } from "@mui/icons-material";

export default function ConversationHelpModal() {
  const instructions = [
    {
      text: "Hover or click on the themes at the bottom to see connections between highlights.",
      icon: <HighlightAlt />,
    },
    {
      text: "Click on a highlight box to hear the highlight and see its themes. ",
      icon: <HighlightAlt />,
    },
    {
      text: "Click on a conversation to see the participants and browse the highlights.",
      icon: <HighlightAlt />,
    },
  ];
  return (
    <InfoModalButton
      title={"Voices By Conversation"}
      icon={<InfoOutlined sx={{ fontSize: "20px" }} />}
      buttonLabel={"Start Exploring"}
      defaultOpen
    >
      <p>
        This visualization is a data exploration of the 26 conversations held
        with JA Worldwide students and alumni. It allows you to explore the
        common themes across the collection and listen to the highlights in
        sequence within the conversations.
      </p>

      <Grid container spacing={1.5} paddingTop={1}>
        {instructions.map(({ text, icon }) => (
          <>
            <Grid item sm={1} xs={2}>
              {icon}
            </Grid>
            <Grid item sm={11} xs={10}>
              {text}
            </Grid>
          </>
        ))}
      </Grid>

      <p>This page is best viewed on desktop.</p>
    </InfoModalButton>
  );
}
