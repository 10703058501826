import { useState } from "react";
import JsxParser from "react-jsx-parser";
import { Stack, Grid, Typography } from "@mui/material";

import ZagatEmbed from "../Audio/ZagatEmbed";
import LinkButton from "../common/buttons/LinkButton";
import HighlightBlock from "./HighlightBlock";
import ConversationModal from "../VoicesView/ConversationView/ConversationModal";

import { MAP_QUERY_ARGS } from "../../utils/utils";

function SummaryBlock({
  title,
  text,
  link,
  selectedEmbedId,
  setSelectedEmbedId,
  selectedEmbedSection,
  setSelectedEmbedSection,
  primaryColor,
  secondaryColor,
  highlights,
  searchLinks,
  sectionId,
  popUp,
}) {
  const [open, setOpen] = useState(null);

  const handleClose = () => {
    setOpen(null);
  };

  const getEmbedInfo = () => {
    return {
      embedSection: link,
      selectedEmbedId,
      setSelectedEmbedId,
      setSelectedEmbedSection,
      primaryColor,
      secondaryColor,
      popUp,
      setOpen,
    };
  };

  const featured = highlights.find(
    (highlight) => highlight.id === parseInt(selectedEmbedId)
  );

  return (
    <>
      {popUp && open && (
        <ConversationModal
          onClose={handleClose}
          highlight={highlights.find((h) => h.id === parseInt(open))}
        />
      )}
      <Grid
        container
        paddingTop={{ xs: 5, md: 0 }}
        id={`${sectionId}-${link}`}
        sx={{ backgroundColor: { xs: "white", sm: "transparent" } }}
        className="jump-link"
      >
        <Grid
          item
          xs={12}
          md={6}
          paddingBottom={{ xs: 2, md: 3 }}
          paddingLeft={{ xs: 5, sm: 10, md: 0 }}
          paddingRight={{ xs: 2, sm: 8, md: 4 }}
        >
          <div>
            <Typography variant="h6" paddingY={3}>
              {title}
            </Typography>
            <div style={{ fontSize: "14px", paddingRight: "2rem" }}>
              <JsxParser
                bindings={{ getEmbedInfo }}
                components={{ Embed: ZagatEmbed }}
                jsx={text}
              />
            </div>
            {searchLinks && (
              <Stack paddingTop={2} spacing={2}>
                {searchLinks.map((link) => (
                  <LinkButton
                    key={`summary-block-link-${title}-${link.label}`}
                    link={`/voices/map-explorer?${MAP_QUERY_ARGS.hideMap}=true&${MAP_QUERY_ARGS.topic}=${title}&${link.queryArg}`}
                  >
                    {link.label}
                  </LinkButton>
                ))}
              </Stack>
            )}
          </div>
        </Grid>
        {!popUp && link === selectedEmbedSection && (
          <HighlightBlock highlight={featured} sectionId={sectionId} />
        )}
      </Grid>
    </>
  );
}

export default SummaryBlock;
