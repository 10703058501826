import Typography from "@mui/material/Typography";

function Speaker({ speaker, primary, secondary, small }) {
  return (
    <>
      <Typography
        variant={small ? "body2" : "body1"}
        sx={{ color: "primary.dark" }}
      >
        <strong>{speaker}</strong>
      </Typography>
      <Typography
        component="span"
        variant={small ? "caption" : "body2"}
        sx={{ color: "gray" }}
      >
        {primary}
        {secondary && `, ${secondary}`}
      </Typography>
    </>
  );
}

export default Speaker;
