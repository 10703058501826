import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import HighlightCarousel from "../../common/carousels/HighlightCarousel";
import LinkButton from "../../common/buttons/LinkButton";
import StyledBackground from "../../common/background/StyledBackground";

import useAnalyticsEventTracker from '../../../tracking/utils';


function HighlightInfo({ selectedTopic, highlightInfo, highlights, indexes, picture}) {



  //need to create key for the image? correct image not loading when click new tab. 
  const gaEventTracker = useAnalyticsEventTracker('Homepage CTA');

  return (
    <StyledBackground picture={picture} texture light>
      <Grid item md={4} sx={{ marginTop: "5rem" }} paddingLeft={8}>
        <Typography
          variant="h6"
          sx={{ paddingBottom: "3rem", color: "white" }}
        >
          {highlightInfo.text}
        </Typography>
        <LinkButton onClick={()=> gaEventTracker('Listen to all prompts')} link={highlightInfo.search} >Listen</LinkButton>
      </Grid>
      <Grid item md={8} paddingLeft="5rem" sx={{ marginTop: "5rem" }}>
        {highlights && (
          <HighlightCarousel
            highlights={highlights}
            indexes={indexes}
            id="normal"
          />
        )}
      </Grid>
    </StyledBackground>
  );
}

export default HighlightInfo;
