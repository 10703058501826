import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import Section from "../../common/background/Section";
import InfoCard from "../../common/cards/InfoCard"

import { TEXTURE } from "../../../config";

import AboutBanner from "../../../assets/about/overview_backgroundyellow.png"; 

function NewsPieceMobile({ config }) {



  return (

    <Grid padding="1.5rem" container sx={{display: {xs: 'block', sm: 'block', md: 'none'}}}>
      <Grid item sm={12} md={5}>
        <InfoCard
          title={config.title}
          link={config.link}
          linkLabel={config.linktitle}
          link2={config.link2}
          link2Label={config.link2title}
          external="true"
          picture={config.picture}
        >
                    
          <p> <strong> {config.source} </strong> | {config.date} </p> 
          <p> {config.description} </p> 
                      
        </InfoCard> 
      </Grid>
      <Grid item sm={12} md={7}  sx={{
                background: `url(${config.picture})`,
                backgroundSize: "cover", 
      }}>
          
      </Grid>
    </Grid> 
  );
}

export default NewsPieceMobile;
