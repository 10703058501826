import { useEffect } from "react";
import { Container, Grid } from "@mui/material";

import InfoCard from "./InfoCard";
import StyledBanner from "../banners/StyledBanner";

function InfoCardView({ config }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container paddingBottom={6}>
      <StyledBanner
        title={config.title}
        body={config.description}
        texture
        light
      />

      <Container maxWidth="lg">
        <Grid
          container
          item
          spacing={5}
          paddingX={{ xs: 3, md: 0 }}
          paddingY={10}
        >
          {config.cards.map((card) => (
            <Grid sm={6} item>
              <InfoCard
                link={card.link}
                title={card.title}
                linkLabel={card.button}
                picture={card.picture}
              >
                <p>{card.body}</p>
              </InfoCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Grid>
  );
}

export default InfoCardView;
