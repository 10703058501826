import React from "react";
import { Box } from "@mui/system";
import BarTooltip from "./BarTooltip";

function Bar({ data, summary }) {
  const percent = (data.count / summary.total) * 100;
  return (
    <BarTooltip data={data} percent={percent} summary={summary}>
      <Box
        style={{
          width: `${percent}%`,
          height: "20px",
          backgroundColor: data.color,
          display: "inline-block",
        }}
      />
    </BarTooltip>
  );
}

export default function StackedBar({ data, summary }) {
  // separate the data into positive and negative
  const positive = data.filter((d) => d.positive);
  const negative = data.filter((d) => d.negative);

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {negative.map((d) => (
        <Bar key={d.name} data={d} summary={summary} />
      ))}
      <div style={{ width: "5px" }} />
      {positive.map((d) => (
        <Bar key={d.name} data={d} summary={summary} />
      ))}
    </div>
  );
}
