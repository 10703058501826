import Approach from "../../../assets/about/approach-rtfc.png";
import TeamRTFC from "../../../assets/about/team-rtfc.jpg";
import InTheMedia from "../../../assets/about/inthemedia-rtfc.png";

export const OVERVIEW = {
  title: "About",
  link: "/about",
  description:
    "Real Talk For Change was an initiative of the MIT Center for Constructive Communication that introduced a new type of communication infrastructure to the city of Boston that facilitated open, nuanced dialogue as well as listening and learning across different segments of the community.",
  cards: [
    {
      title: "Team",
      body: "Meet the people behind Real Talk For Change.",
      button: "Learn More",
      link: "team",
      picture: TeamRTFC,
    },
    {
      title: "Our Process",
      body: "Boston community members joined facilitated conversations to share their hopes and concerns about the future of Boston and their place in it.",
      button: "Learn More",
      link: "process",
      picture: Approach,
    },
    {
      title: "In the Media",
      body: "Real Talk featured in the news.",
      button: "Learn More",
      link: "media",
      picture: InTheMedia,
    },
  ],
};
