import { useEffect, useState } from "react";
import { tagToTitle } from "./conversationUtils";

function ConversationButtonTags({ drawConnections, tags, tagsToColor }) {
  const [activeButtons, updateActiveButtons] = useState([]);

  const [hoverButtons, updateHoverButtons] = useState([]);

  const buttonTagClick = (e) => {
    const id = e.target.id;

    if (activeButtons.includes(id)) {
      updateActiveButtons((arr) => {
        const index = arr.indexOf(id);
        if (index > -1) {
          arr.splice(index, 1);
        }
        return [...arr];
      });
    } else {
      updateActiveButtons((arr) => [...arr, id]);
    }
  };

  const buttonTagMouseOver = (e) => {
    const id = e.target.id;

    if (activeButtons.includes(id) || hoverButtons.includes(id)) {
      return;
    }
    updateHoverButtons((arr) => [...arr, id]);
  };

  const buttonTagMouseOut = (e) => {
    const id = e.target.id;

    if (activeButtons.includes(id) || !hoverButtons.includes(id)) {
      return;
    }

    updateHoverButtons((arr) => {
      const index = arr.indexOf(id);
      if (index > -1) {
        arr.splice(index, 1);
      }
      return [...arr];
    });
  };

  useEffect(() => {
    drawConnections(activeButtons, hoverButtons);
  }, [activeButtons, hoverButtons]);

  return (
    <div class="tag-container">
      {tags.map((tag) => {
        return (
          <div class="button-container">
            <button
              id={tag}
              className={[
                tag,
                tagsToColor[tag],
                "tag-button",
                activeButtons.includes(tag) ? "active" : "",
              ].join(" ")}
              onClick={buttonTagClick}
              onMouseOver={buttonTagMouseOver}
              onMouseOut={buttonTagMouseOut}
            >
              {tagToTitle(tag)}
            </button>
          </div>
        );
      })}
    </div>
  );
}

export default ConversationButtonTags;
