import Grid from "@mui/material/Grid";

function Transcript({ sectionId, hid, extend, compact }) {
  return (
    <Grid
      item
      xs={12}
      id={`${sectionId}-embed-minimal-${hid}`}
      style={{
        height: extend
          ? `${compact ? 184 : 134}px`
          : `${compact ? 164 : 104}px`,
      }}
      paddingX="1rem"
    />
  );
}

export default Transcript;
