import React from "react";
import { Box } from "@mui/material";

import BarTooltip from "./BarTooltip";
import { BarSegment } from "./BarSegment";

export default function Bar({ decimal, data }) {
  return (
    <>
      <BarTooltip percent={decimal * 100} data={data}>
        {
          // this should use BarSegment, but the tooltip then doesn't work
        }
        <Box
          style={{
            width: `${decimal * 100}%`,
            height: "100%",
            backgroundColor: data.color,
            float: "left",
          }}
        />
      </BarTooltip>

      <BarSegment decimal={1 - decimal} color="#E5E5E5" right />
    </>
  );
}
