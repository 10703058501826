import { useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { Helmet } from 'react-helmet-async';

import StyledBanner from "../common/banners/StyledBanner";
import TopicCard from "./TopicCard";

import { OVERVIEW } from "./config";
import InsightsBanner from "../../assets/banners/allinsightsbanner.png"

function Insights({ topics }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container>

      <Helmet>
       <title> Insights Page </title>
       <meta name='description' content='Topic Overviews' />
     </Helmet>
      <StyledBanner title={OVERVIEW.title} body={OVERVIEW.body} picture={InsightsBanner} light />

      <Container maxWidth="lg">
        <Grid
          container
          item
          spacing={5}
          paddingX={{ xs: 3, md: 0 }}
          paddingY={10}
        >
          {topics.map((topic) => (
            <Grid sm={6} md={4} item>
              <TopicCard
                topicId={topic.id}
                topicInfo={topic}
                picture={OVERVIEW.banners[topic.id]?.src}
                preview
                small
                icon={OVERVIEW.banners[topic.id]?.icon}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Grid>
  );
}

export default Insights;
