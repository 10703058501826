// HeaderImg
import Participants from "../../../assets/landing-page/mosaic.png";

// News Piece Pictures
import BostonGlobe1 from "../../../assets/about/media/bostonglobe1.jpeg";
import BostonGlobe2 from "../../../assets/about/media/bostonglobe2.png";
import BNN from "../../../assets/about/media/media-bnn.png";
import GBH from "../../../assets/about/media/gbh.png";

export const INTRODUCTION = {
  "title": "How does this work?",
  "body": "Every three years, JA Worldwide develops the strategic plan that guides our growth and priorities. For the first time, JA Worldwide invited young people to add their voices as part of an advisory group and share their JA experiences. JA Worldwide has partnered with Cortico and the MIT Center for Constructive Communication to provide tools to highlight, analyze, and share these conversations."
}

export const NEWSPIECES = {
  news: [
    {
      title: "Michelle Wu and Annissa Essaibi George respond to clips from Real Talk Conversations with Boston Residents",
      source: "Boston Neighborhood Network",
      date: "October 29, 2021",
      link: "https://guide.bnnmedia.org/CablecastPublicSite/show/105482?channel=3", 
      linktitle: "Annissa Essaibi George",
      link2: "https://guide.bnnmedia.org/CablecastPublicSite/show/105483?channel=3", 
      link2title: "Michelle Wu", 
      picture: BNN, 

    },
    {
       title: "The Boston Globe announces the launch of the RTFC Conversation Portal",
        source: "The Boston Globe",
        author: "Meghan E. Irons, Globe Staff",
        description: "In a community dialogue in East Boston this summer, a man named Alejandro inquired about whether the city’s high schools would receive the funding they need to thrive. In West Roxbury, a resident named Beth expressed weariness about electing “transactional leaders instead of transformational leaders that are uncorruptible.” And a Dorchester woman named Nina raised a question about whether anyone in government is really listening to residents’ concerns about the dramatic changes occurring in their communities.",
        date: "October 25, 2021",
        link: "https://www.bostonglobe.com/2021/10/25/metro/some-bostonians-feel-largely-unheard-with-mits-real-talk-portal-now-public-heres-chance-really-listen/", 
        linktitle: "Read on the Boston Globe", 
        picture: BostonGlobe2, 
    },
    {
        title: "Boston’s mayoral election: How far the city has come, and how far it has to go",
        source: "GBH",
        description: "Watch here the full interview hosted by Adam Reilly with our Campaign Manager Ron Bell and Project Lead Ceasar McDowell. On September 17th, Real Talk for Change participated in GBH’s “Boston Race into History” news segment. We talked about how we are helping create a mayoral campaign that is grounded in Bostonians’ lived experiences.",
        date: "September 17, 2021",
        link: "https://www.youtube.com/watch?v=ZMWzB_E2TgA&feature=emb_logo", 
        linktitle: "Watch now", 
        picture: GBH, 
    },
    {
        title: "MIT’s ‘Real Talk’ Campaign Gives Likely Voters a Voice in Boston’s Race for Mayor",
        source: "The Boston Globe",
        description: "For the past several months, Boston residents have heard a lot from the candidates running for mayor about their aspirations for leading this city. But on Monday, a half-dozen residents came together as a part of a new civic engagement campaign, aimed at refocusing the narrative and giving voice to regular people, especially those who feel ignored. The effort, called ‘Real Talk for Change,’ was launched this summer by MIT researchers, who have enlisted community facilitators to convene roughly 600 residents from different parts of the city to share their stories—and dreams—about the future of Boston.",
        date: "August 24, 2021",
        link: "https://www.bostonglobe.com/2021/08/24/metro/mits-real-talk-campaign-gives-likely-voters-voice-bostons-race-mayor/", 
        linktitle: "Read on the Boston Globe", 
        picture: BostonGlobe1, 
    }
  ],
};

