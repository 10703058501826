import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";

function BreadcrumbLinks({ navigation, color }) {
  return (
    <>
      <Breadcrumbs sx={{ color: color, paddingBottom: ".25rem" }}>
      <Link
            to="/"
            style={{
              textDecoration: "none",
              color: color,
              fontSize: "14px"
            }}
          >
            Home
          </Link>
          <Link
            to={navigation.link}
            style={{
              textDecoration: "none",
              color: color,
              fontSize: "14px"
            }}
          >
            {navigation.title}
          </Link>
      </Breadcrumbs>
    </>
  );
}

export default BreadcrumbLinks;
