import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Button, Collapse, Typography } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

import affectMetaData from "../../assets/content/affects-rtfc.json";
import { enrichCommunityAffectData, getCamelCase } from "../../utils/utils";
import { AFFECT_CATEGORY_COLORS } from "./config";

import Bar from "./Bar";
import StackedBar from "./StackedBar";
import { Percent } from "./Percent";

function AffectRow({ data, summary }) {
  const decimal = data.count / summary.total;
  return (
    <>
      <Percent decimal={decimal} positive={data.positive} />
      <Grid item xs={4}>
        <Typography>{getCamelCase(data.name)}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Bar data={data} decimal={decimal} summary={summary} />
      </Grid>
    </>
  );
}

function CommunityAffectBar({ data }) {
  const { totals, summary } = enrichCommunityAffectData(data, affectMetaData);

  // copy of the totals ordered by count
  const sortedTotals = [...totals].sort((a, b) => b.count - a.count);

  const [showMore, setShowMore] = useState(false);
  const shortSize = 3;
  const spaceBetweenRows = 0.5;
  const iconStyles = {
    transform: "scale(0.75)",
    transformOrigin: "bottom",
  };

  return (
    <>
      <Grid container rowSpacing={spaceBetweenRows} py={2}>
        <Grid item xs={6}>
          <Typography
            align="left"
            style={{ color: AFFECT_CATEGORY_COLORS.negative }}
          >
            Negative Feelings
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            align="right"
            style={{ color: AFFECT_CATEGORY_COLORS.positive }}
          >
            Positive Feelings
          </Typography>
        </Grid>
        <Percent
          decimal={summary.negativeTotal / summary.total}
          positive={false}
        />
        <Grid item xs={8}>
          <StackedBar data={totals} summary={summary} />
        </Grid>
        <Percent
          decimal={summary.positiveTotal / summary.total}
          positive
          right
        />
        <Grid item xs={2} />
        <Grid item xs={10}>
          <Typography variant="caption">
            +
            {Math.abs(
              Math.round((summary.positiveTotal / summary.total) * 100) -
                Math.round((summary.negativeTotal / summary.total) * 100)
            )}
            % more{" "}
            {summary.positiveTotal > summary.negativeTotal
              ? "positive"
              : "negative"}
          </Typography>
        </Grid>
      </Grid>

      <Grid container rowSpacing={spaceBetweenRows}>
        {sortedTotals.slice(0, shortSize).map((d) => (
          <AffectRow key={d.name} data={d} summary={summary} />
        ))}
      </Grid>
      <Collapse in={showMore}>
        <Grid
          container
          rowSpacing={spaceBetweenRows}
          paddingTop={spaceBetweenRows}
        >
          {sortedTotals.slice(shortSize, sortedTotals.length).map((d) => (
            <AffectRow key={d.name} data={d} summary={summary} />
          ))}
        </Grid>
      </Collapse>
      <Grid container>
        <Grid item xs={2} />
        <Grid item xs={10}>
          <Button
            onClick={() => setShowMore(!showMore)}
            sx={{
              fontSize: "0.75rem",
              textTransform: "none",
              display: "inline-block",
              padding: "0",
              minWidth: "0",
              color: "primary.dark",
            }}
            endIcon={
              showMore ? (
                <ArrowUpward sx={iconStyles} />
              ) : (
                <ArrowDownward sx={iconStyles} />
              )
            }
          >
            {showMore ? "Show Less" : "See All"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default CommunityAffectBar;
