import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useParams } from "react-router";
import { Helmet } from 'react-helmet-async';


import TopicNav from "./TopicNav";
import SummaryContainer from "./SummaryContainer";
import SummaryContainerMobile from "./SummaryContainerMobile";
import SplitBackgroundGrid from "../common/background/SplitBackgroundGrid";
import StyledBanner from "../common/banners/StyledBanner";

import { OVERVIEW } from "./config";

import CommunityAffectData from "../../assets/content/community_affect_counts.json";
import CommunityAffectBar from "./CommunityAffectBar";

import HousingIcon from "../../assets/topic-icons/icon-housing.png"; 
import GovernmentInstitutionsIcon from "../../assets/topic-icons/icon-governmentandinstitutions.png"; 
import PublicHealthIcon from "../../assets/topic-icons/icon-publichealth.png"; 
import InequalityIcon from "../../assets/topic-icons/icon-inequality.png"; 
import EconomicOpportunityIcon from "../../assets/topic-icons/icon-economicopportunity.png"; 
import EducationIcon from "../../assets/topic-icons/icon-education.png"; 
import CommunityLifeIcon from "../../assets/topic-icons/icon-communitylife.png"; 
import InfrastructureIcon from "../../assets/topic-icons/icon-infastructure.png"; 
import SafetyIcon from "../../assets/topic-icons/icon-safety.png"; 



function AffectSection({ topicInfo }) {
  return (
    <>
      <Typography variant="h6" paddingBottom={3} paddingTop={{xs: 7, md: 3}}>
        Collective sentiments around {topicInfo.label}
      </Typography>
      <div style={{ fontSize: "14px", paddingRight: "2rem", paddingBottom: "2rem" }}> 
          Alongside all their stories and experiences, residents across Boston shared feelings of frustration, betrayal, manipulation, disempowerment, and hopelessness. Uplifting these sentiments, together with the emerging themes that surfaced, can help remove feelings of isolation and being unheard and hopefully build a bridge across neighbors and communities.
      </div> 
      <CommunityAffectBar
        data={CommunityAffectData[topicInfo.id.split("-").join(" ")]}
      />
    </>
  );
}

function TopicViewContainer({ topics, highlights }) {
  const [currSection, setCurrSection] = useState("Overview");

  const { id } = useParams();
  const topicInfo = topics.find((topic) => topic.id === id);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = (section) => {
    setCurrSection(section);
  };

  const handleChangeDropdown = (event) => {
    setCurrSection(event.target.value);

    const subTopicInfo = topicInfo.subTopics.find(
      (subTopic) => subTopic.label === event.target.value
    );

    const element = document.querySelector(`#mobile-${subTopicInfo.link}`);
    const topPos =
      element.getBoundingClientRect().top + window.pageYOffset - 80;

    window.scrollTo({
      top: topPos,
      behavior: "smooth",
    });
  };

  const handleEnterWaypoint = (section) => {
    setCurrSection(section);
  };




  return (
    <Grid>
      <Helmet>
       <title> Insights Page  / {topicInfo.label} </title>
       <meta name='description' content='Insights Page / Specific Topic' />
     </Helmet>
      <StyledBanner
        title={topicInfo.label}
        body=""
        picture={OVERVIEW.banners[topicInfo.id].src}
        navigation={OVERVIEW}
        light
        icon={OVERVIEW.banners[topicInfo.id].icon}
      />
      <TopicNav
        topics={topicInfo.subTopics}
        onClick={handleClick}
        onChange={handleChangeDropdown}
        currSection={currSection}
        primaryColor={topicInfo.primaryColor}
      />
      <Grid item sx={{ display: { xs: "none", md: "block" } }}>
        <SplitBackgroundGrid>
          <Grid container item xs={6} 
            paddingBottom={{ xs: 2, md: 3 }}
            paddingLeft={{ xs: 5, sm: 10, md: 0 }}
            paddingRight={{ xs: 2, sm: 8, md: 4 }}
          >
            <AffectSection topicInfo={topicInfo} />
          </Grid>
          <SummaryContainer
            topicInfo={topicInfo}
            currSection={currSection}
            highlights={highlights}
            onEnterWaypoint={handleEnterWaypoint}
            sectionId="screen"
          />
        </SplitBackgroundGrid>
      </Grid>
      <Grid
        item
        xs={12}
        paddingBottom={10}
        sx={{ backgroundColor: "white", display: { xs: "block", md: "none" } }}
      >
        <Grid item container xs={12} 
            paddingLeft={{ xs: 5, sm: 10, md: 0 }}
            paddingRight={{ xs: 5, sm: 10, md: 4 }}>
          <AffectSection topicInfo={topicInfo} />
        </Grid>
        <SummaryContainerMobile
          topicInfo={topicInfo}
          currSection={currSection}
          highlights={highlights}
          onEnterWaypoint={handleEnterWaypoint}
          sectionId="mobile"
        />
      </Grid>
    </Grid>
  );
}

export default TopicViewContainer;
