import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

function Banner({ children, tabs: subNavigation, sx }) {
  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        sx={sx}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <Grid
            item
            xs={12}
            md={7}
            marginY={15}
            marginX={{ xs: 3, sm: 6, md: 0 }}
          >
            {children}
          </Grid>
          {subNavigation}
        </Container>
      </Grid>
    </Grid>
  );
}

export default Banner;
