import { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import JsxParser from "react-jsx-parser";
import { Helmet } from 'react-helmet-async';

import StyledBanner from "../../common/banners/StyledBanner";
import ProcessStep from "./ProcessStep";
import Section from "../../common/background/Section";
import ProcessCardTabContainer from "./ProcessCardTabContainer";

import BannerImg from "../../../assets/about/processbanner.png";

import IntroVideo from "./IntroVideo"; 

import ConversationVideo from "../../../assets/about/Process_FacilitatedConversations.mp4";
import ConversationVideoThumbnail from "../../../assets/about/Process_FacilitatedConversations_Thumbnail.png";

import { OVERVIEW } from "../config/config";
import { INTRODUCTION, STEPS } from "./config";
const SECTION = "process";

const Process = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const section = OVERVIEW.cards.find((card) => card.link === SECTION);

  return (
    <div style={{ background: "#FDF7F2" }}>
      <Helmet>
       <title>About Page / Process </title>
       <meta name='description' content='About Page / Process' />
     </Helmet>
      <StyledBanner
        title={section.title}
        picture={BannerImg}
        navigation={OVERVIEW}
        light 
      />
      <IntroVideo /> 
      
      <Grid marginTop={-5}>
        {STEPS.map((step, idx) => (
          <>
            <Section margin={5}>
              <Grid item xs={12} marginBottom={10} marginTop={6}>
                <ProcessStep
                  step={idx + 1}
                  title={step.title}
                  imageSrc={step.picture}
                >
                  <JsxParser jsx={step.body} />
                </ProcessStep>
              </Grid>
            </Section>



            {step.sections && (
              <Section dark>
                <Box
                  paddingY={6}
                  xs={12}
                  marginTop={{ xs: "-95px", sm: "-90px" }}
                >
                  <ProcessCardTabContainer
                    config={step.sections}
                    label={step.label}
                  />
                </Box>
              </Section>
            )}
            
            {console.log(step.nosections)}

            {step.nosections && (
              <Grid marginTop={-5}>
                <Section dark>
                  <Grid contianer item xs={12} marginBottom={5} marginTop={5}>
                      <video controls width ="100%" poster={ConversationVideoThumbnail}>
                          <source src={ConversationVideo} type="video/mp4"/>
                          Sorry, your browser doesn't support videos.
                      </video> 
                  </Grid>
                </Section>
              </Grid>
            )}
          </>
        ))}

      
      </Grid>
    </div>
  );
};

export default Process;
