import { useEffect } from "react";
import { Helmet } from 'react-helmet-async';

import StyledBanner from "../../common/banners/StyledBanner";
import HeaderAndMedia from "../../common/text/HeaderAndMedia";
import Introduction from "./Introduction"; 

import StaffCallout from "./StaffCallout";
import Participants from "./Participants";
import Partners from "./Partners";

import TeamBannerPicture from "../../../assets/about/teambanner.png"; 

import { OVERVIEW } from "../config/config";
import { ABOUTUS, ILLUSTRATIONS, FACILITATORS, STAFF, PARTICIPANTS, PROJECTLEADS, PROJECTADVISORS, COMMUNICATIONS, CCCTEAM, CORTICOTEAM, PARTNERS, CTA } from "./config";
const SECTION = "team";

function Team() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const section = OVERVIEW.cards.find((card) => card.link === SECTION);

  return (
    <div style={{ background: "#FCF7F3" }}>
      <Helmet>
        <title>About Page / Team </title>
        <meta name='description' content='About Page / Team' />
      </Helmet>
      <StyledBanner
        title={section.title}
        picture={TeamBannerPicture}
        navigation={OVERVIEW}
        light 
      />
      
      <Introduction config={ABOUTUS} />

      {ILLUSTRATIONS.illustrations.map((post) =>
        <HeaderAndMedia
        title={post.title}
        body={post.body}
        media={post.media}
        swap
        transparent
        offset
        />
      )}

      <StaffCallout config={FACILITATORS} /> 

      <StaffCallout config={PROJECTLEADS} /> 

      <StaffCallout config={PROJECTADVISORS} /> 

      <StaffCallout config={COMMUNICATIONS} /> 

      <StaffCallout config={CCCTEAM} /> 

      <StaffCallout config={CORTICOTEAM} /> 
    
      <Partners config={PARTNERS} />
    </div>
  );
}

export default Team;
