import { Grid, Container } from "@mui/material";

function SplitBackgroundGrid({ children }) {
  return (
    <Grid
      item
      xs={12}
      paddingTop={6}
      paddingBottom={10}
      sx={{
        background: "linear-gradient(to right, #ffffff 50%, rgba(0,0,0,0) 50%)",
      }}
    >
      <Container maxWidth="lg">{children}</Container>
    </Grid>
  );
}

export default SplitBackgroundGrid;
