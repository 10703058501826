import { Box, Typography, Grid } from "@mui/material";

function CenterHeader({ title, body, padding }) {
  return (
    <Grid
      container
      item
      xs={12}
      direction="column"
      alignItems="center"
      justifyContent="center"
      paddingTop={4}
      paddingBottom={padding ? 12 : 6}
    >
      <Grid xs={10} md={7}>
        <Box sx={{ color: "inherit" }}>
          <Typography align="center" variant="h5" paddingBottom={2}>
            {title}
          </Typography>
          {body && (
            <Typography align="center" variant="body2">
              {body}
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default CenterHeader;
