import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ArrowDownward } from "@mui/icons-material";

import { TEXTURE } from "../../../config";
import DownArrow from "../../../assets/landing-page/arrow.png"; 


function SplitBanner({ title, subtitle, body, picture, texture, light, large, arrow }) {
  return (
    <Grid container>
      <Grid
        item
        lg={5}
        md={6}
        paddingX={{ xs: 5, sm: 8}}
        paddingTop={10}
        paddingBottom={20}
        sx={{
          color: light ? "primary.dark" : "primary.main",
          backgroundColor: light ? "primary.main" : "primary.light",
          background: texture ? `url(${TEXTURE})` : "primary.dark",
          backgroundSize: "cover",
        }}
      >
        <Typography variant="h4" paddingBottom={2}>
          {title}
        </Typography>
        <Typography variant="h5" paddingBottom={6}>
          {subtitle}
        </Typography>

        <Typography variant="h6">{body}</Typography>
        {arrow && (
          <ArrowDownward sx={{ paddingTop: "2rem", marginBottom: "6rem" }}>
          </ArrowDownward>
   
        )}
      </Grid>
      <Grid
        item
        lg={7}
        md={6}
        sx={{
          display: { xs: "none", md: "block" },
          background: `url(${picture})`,
          backgroundSize: "cover",
          minHeight: large ? "65vh" : "50vh",
        }}
      ></Grid>
    </Grid>
  );
}

export default SplitBanner;
