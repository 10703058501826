import { Dialog, DialogContent } from "@mui/material";
import Highlight from "../../Audio/HighlightWrapper";

function ConversationModal({ onClose, highlight }) {
  return (
    <Dialog onClose={onClose} open={highlight !== undefined}>
      <DialogContent>
        {highlight && (
          <Highlight
            highlight={highlight}
            sectionId="conversation-visualization"
            scrolling
            extend
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default ConversationModal;
