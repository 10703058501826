import { Grid, Box } from "@mui/material";
import Highlight from "../Audio/HighlightWrapper";

function HighlightBlock({ highlight, sectionId }) {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      padding={6}
      sx={{
        display: { xs: "none", sm: "block" },
        height: "100px",
        overflow: "visible",
      }}
    >
      <Box sx={{ display: { sm: "none", md: "block" } }}>
        <Highlight
          sectionId={`summary-${sectionId}-desktop`}
          highlight={highlight}
          scrolling
          extend
        />
      </Box>
      <Box sx={{ display: { sm: "block", md: "none" } }}>
        <Highlight
          sectionId={`summary-${sectionId}-tablet`}
          highlight={highlight}
          compact
          scrolling
          extend
        />
      </Box>
    </Grid>
  );
}

export default HighlightBlock;
