// Pictures for each section
import Participants from "../../../assets/landing-page/mosaic.png";
import AboutUsBanner from "../../../assets/about/aboutbanner.png"; 

// Partner Logos
import CCC from "../../../assets/partners/ccc-logo.png";
import Cortico from "../../../assets/partners/cortico-logo.png";
import DunkTheVote from "../../../assets/partners/dunkthevote-logo.png";
import TheBostonFoundation from "../../../assets/partners/tbf-logo.png";
import CircleofNations from "../../../assets/partners/circleofnations-logo.png";
import SouthEndTechnologyCenter from "../../../assets/partners/setc-logo.png";
import MetroBostonAlive from "../../../assets/partners/metrobostonalive-logo.png";
import FreedomHouse from "../../../assets/partners/freedomhouse-logo.png";
import FamilyNurturing from "../../../assets/partners/familynurturingcenter-logo.png";

//Illustrations
import IllCommTech from "../../../assets/about/overviewill_1.png";
import IllLeadersConvos from "../../../assets/about/overviewill_2.png";
import IllThreeWayTrust from "../../../assets/about/overviewill_3.png";



export const ABOUTUS = {
  title: "About Us",
  body: "Real Talk For Change was a collaboration between the MIT Center for Constructive Communication, Cortico, Dunk the Vote, The Boston Foundation, Circle of Nations, South End Technology Center, Family Nurturing Center, and Metro Boston Alive. Our goal was to introduce a new element of civic infrastructure in which the voice of the public grows out of people sharing their experiences of living in Boston. More specifically, we wanted to amplify the voices and gain the engagement of folks who are too often underheard by current civic processes. Real Talk For Change provided a means for Bostonians to cut through the noise of opinion makers and finally be heard.",
  picture: AboutUsBanner, 
}; 


export const CTA = {
  title: "Join Gather Today!",
  media: CCC,
  link: "https://gatheralumni.org",
  body:
    "Were you apart of JA, Junior Achievement, Young Enterprise, INJAZ, Impulsa, or any of the dozens of ways that JA is translated around the world? If so, you’re eligible to <a className='jsx-link' target='_blank' href='https://gatheralumni.org'>join Gather</a>, the JA alumni community, where doing good is just part of doing business.",
};


export const ILLUSTRATIONS ={
  illustrations: [
    {
      title: "Community Organizing Meets Technology ", 
      media: IllCommTech, 
      body: 
        "Bringing together age-old methods of facilitated small group dialogue, and community organizing with modern machine learning, data visualization, and social network design, our technology enables communities to organize conversations across the full diversity of their population, identify patterns of what people share across these conversations, and channel these voices to influence decision making in a more grounded and direct way than ever before.", 
    }
  ]
}; 

export const FACILITATORS = {
  title: "Meet Our Facilitators",
  body:
    "Real Talk For Change Facilitators, dedicated champions of their communities and skilled in the art of communication, had earned the trust of their neighbors. Together, they embarked on a collective mission to invite members from their communities to join these conversations with the mission to reform our democracy in the name of equity. ",
  people: [
    {
      name: "Zakiya Alake",
      description: "Facilitator Trainer",
      pic: true, 
    },
    {
      name: "Dawn Duncan",
      description: "Facilitator Trainer",
      pic: true, 
    },
    {
      name: "Francesca Smith",
      description: "Facilitator Trainer",
      pic: true, 
    },
    {
      name: "Corey Thompson",
      description: "Facilitator",
      pic: true, 
    },
    {
      name: "Mike King",
      description: "Facilitator",
      pic: true, 
    },
    {
      name: "Marilyn Forman",
      description: "Facilitator",
      pic: true, 
    },
    {
      name: "Shareef Smith",
      description: "Facilitator",
      pic: true, 
    },
    {
      name: "Nurys Camargo",
      description: "Facilitator",
      pic: false, 
    },
    {
      name: "Elvis Rosario",
      description: "Facilitator",
      pic: false, 
    },
    {
      name: "Rebecca Lin",
      description: "Facilitator",
      pic: true, 
    },
    {
      name: "Reverend Don Nanstad",
      description: "Facilitator",
      pic: false, 
    },
    {
      name: "Iyanna Ziona Stewart",
      description: "Facilitator",
      pic: false, 
    },
    {
      name: "Jacqueline Martínez",
      description: "Facilitator",
      pic: true, 
    },
    {
      name: "George Halfkenny",
      description: "Facilitator",
      pic: false, 
    },

  ],
};
/*
export const STAFF = {
  title: "Meet Our Staff",
  body:
    "",
  people: [
    {
      name: "Ron Bell",
      description: "Campaign Manager, Real Talk For Change & Director, Dunk the Vote",
      pic: true, 
    },
    {
      name: "Ceasar McDowell",
      description: "Associate Director, Center for Constructive Communication & Professor, Department of Urban Studies and Planning, MIT",
      pic: true, 
    },
    {
      name: "Deb Roy",
      description: "Director, Center for Constructive Communication & Professor, Professor, Program in Media Arts and Sciences, MIT",
      pic: true, 
    },
    {
      name: "Karilyn Crockett",
      description: "Assistant Professor of Urban History, Public Policy & Planning, MIT",
      pic: true, 
    },
    {
      name: "Michael Jacoby Brown",
      description: "Organizer, Trainer and Consultant",
      pic: true, 
    },
    {
      name: "Eldin Villafañe",
      description: "Public Relations",
      pic: true, 
    },
    {
      name: "Perris Richter",
      description: "Head of Design, CCC",
      pic: true, 
    },
    {
      name: "Vandinika Shukla",
      description: "Strategist, CCC",
      pic: true, 
    },
    {
      name: "Lorena Tovar",
      description: "Project Coordinator, CCC",
      pic: true, 
    },
    {
      name: "Sarah Ballinger",
      description: "Head of Program Management, CCC",
      pic: true, 
    },
    {
      name: "Dimitra Dimitrakopoulou",
      description: "Visiting Assistant Professor, CCC",
      pic: true, 
    },
    {
      name: "Maggie Hughes",
      description: "Research Assistant, CCC",
      pic: true, 
    },
    {
      name: "Dennis Jen",
      description: "Lead Research Software Engineer, CCC",
      pic: true, 
    },
    {
      name: "Artemisia Luk",
      description: "Designer, CCC",
      pic: true, 
    },
    {
      name: "Wonjune Kang",
      description: "Research Assistant, CCC",
      pic: true, 
    },
    {
      name: "Doug Beeferman",
      description: "Research Scientist, CCC",
      pic: true, 
    },
    {
      name: "Nabeel Gillani",
      description: "Postdoctoral Associate, CCC",
      pic: true, 
    },
    {
      name: "Ellen Hoffman",
      description: "Special Projects, CCC",
      pic: true, 
    },
    {
      name: "Thanh Mai Phan",
      description: "Research Software Engineer, CCC",
      pic: true, 
    },
    {
      name: "Heather Pierce",
      description: "Head of Operations, CCC",
      pic: true, 
    },
    {
      name: "Andrew Heyward",
      description: "Advisor, CCC",
      pic: true, 
    },
    {
      name: "Tibisay Zea",
      description: "Content Creator, CCC",
      pic: true, 
    },
    {
      name: "Wes Chow",
      description: "Head of Engineering, CCC",
      pic: true, 
    },
    {
      name: "Ondina Frate",
      description: "Research Software Engineer, CCC",
      pic: false, 
    },
    {
      name: "Maridena Rojas",
      description: "CCC Fellow",
      pic: true, 
    },
    {
      name: "Somala Diby",
      description: "CCC Fellow",
      pic: true, 
    },
    {
      name: "Alex Kelly Berman",
      description: "Director of Partnership Strategy, Cortico",
      pic: true, 
    },
    {
      name: "David van Dokkum",
      description: "COO, Cortico",
      pic: true, 
    },
    {
      name: "Kelly Paola",
      description: "Partner Support Specialist, Cortico",
      pic: true, 
    },
  ],
};
*/ 

export const PROJECTLEADS = {
  title: "Project Leads",
  body:
    "",
  people: [
    {
      name: "Ron Bell",
      description: "Campaign Manager, Real Talk For Change & Director, Dunk the Vote",
      pic: true, 
    },
    {
      name: "Ceasar McDowell",
      description: "Associate Director, Center for Constructive Communication (Past Member), & Professor, Department of Urban Studies and Planning, MIT",
      pic: true, 
    },
    {
      name: "Deb Roy",
      description: "Director, Center for Constructive Communication & Professor, Professor, Program in Media Arts and Sciences, MIT",
      pic: true, 
    },
  ],
};



export const PROJECTADVISORS = {
  title: "Project Advisors",
  body:
    "",
  people: [
    {
      name: "Karilyn Crockett",
      description: "Assistant Professor of Urban History, Public Policy & Planning, MIT",
      pic: true, 
    },
    {
      name: "Michael Jacoby Brown",
      description: "Organizer, Trainer and Consultant",
      pic: true, 
    }
  ],
};



export const COMMUNICATIONS = {
  title: "Communications",
  body:
    "",
  people: [
    {
      name: "Eldin Villafañe",
      description: "Public Relations",
      pic: true, 
    },
  ],
};


export const CCCTEAM = {
  title: "CCC Team",
  body:
    "",
  people: [
    {
      name: "Perris Richter",
      description: "Head of Design, CCC (Past Member)",
      pic: true, 
    },
    {
      name: "Vandinika Shukla",
      description: "Strategist, CCC (Past Member)",
      pic: true, 
    },
    {
      name: "Lorena Tovar",
      description: "Project Coordinator, CCC",
      pic: true, 
    },
    {
      name: "Sarah Ballinger",
      description: "Head of Program Management, CCC (Past Member)",
      pic: true, 
    },
    {
      name: "Dimitra Dimitrakopoulou",
      description: "Head of Translational Research, CCC",
      pic: true, 
    },
    {
      name: "Maggie Hughes",
      description: "Research Assistant, CCC",
      pic: true, 
    },
    {
      name: "Dennis Jen",
      description: "Lead Research Software Engineer, CCC",
      pic: true, 
    },
    {
      name: "Artemisia Luk",
      description: "Design Lead, CCC",
      pic: true, 
    },
    {
      name: "Wonjune Kang",
      description: "Research Assistant, CCC",
      pic: true, 
    },
    {
      name: "Doug Beeferman",
      description: "Research Scientist, CCC",
      pic: true, 
    },
    {
      name: "Nabeel Gillani",
      description: "Postdoctoral Associate, CCC (Past Member)",
      pic: true, 
    },
    {
      name: "Ellen Hoffman",
      description: "Communications Strategist, CCC",
      pic: true, 
    },
    {
      name: "Thanh Mai Phan",
      description: "Research Software Engineer, CCC",
      pic: true, 
    },
    {
      name: "Heather Pierce",
      description: "Head of Operations and Administration, CCC",
      pic: true, 
    },
    {
      name: "Andrew Heyward",
      description: "Advisor, CCC",
      pic: true, 
    },
    {
      name: "Wes Chow",
      description: "Head of Engineering, CCC",
      pic: true, 
    },
    {
      name: "Tibisay Zea",
      description: "Community Fellow (Past Member)",
      pic: true, 
    },
    {
      name: "Maridena Rojas",
      description: "Community Fellow (Past Member)",
      pic: true, 
    },
    {
      name: "Somala Diby",
      description: "Community Fellow (Past Member)",
      pic: true, 
    }
  ],
};


export const CORTICOTEAM = {
  title: "Cortico Team",
  body:
    "",
  people: [
    {
      name: "Alex Kelly Berman",
      description: "VP of Programs & Partnerships, Cortico",
      pic: true, 
    },
    {
      name: "David van Dokkum",
      description: "Advisor, Cortico",
      pic: true, 
    },
    {
      name: "Kelly Paola",
      description: "Product Lead, Cortico",
      pic: true, 
    },
  ],
};




export const PARTNERS = {
  title: "Our Partners",
  body:
    "",
  organizations: [
    {
      name: "MIT Center for Constructive Communication",
      img: CCC,
      href: "https://www.ccc.mit.edu/",
    },
    {
      name: "Cortico",
      img: Cortico,
      href: "https://cortico.ai/",
    },
    {
      name: "Dunk the Vote",
      img: DunkTheVote,
      href: "https://dunkthevote4ever.org/",
    },
    {
      name: "The Boston Foundation",
      img: TheBostonFoundation,
      href: "https://www.tbf.org/",
    },
    {
      name: "Circle of Nations Inc.",
      img: CircleofNations,
      href: "https://circleofnations.org/",
    },
    {
      name: "South End Technology Center",
      img: SouthEndTechnologyCenter,
      href: "http://southendtechcenter.org/",
    },
    {
      name: "Metro Boston Alive",
      img: MetroBostonAlive,
      href: "https://m.facebook.com/pages/category/Local-Business/Metro-Boston-Alive-543673345775187/",
    },
    {
      name: "Freedom House",
      img: FreedomHouse,
      href: "https://freedomhouse.com/",
    },
    {
      name: "Family Nurturing Center",
      img: FamilyNurturing,
      href: "https://familynurturing.org/",
    },
  ],
};
