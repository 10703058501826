import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { AFFECT_CATEGORY_COLORS } from "./config";

export function Percent({ decimal, positive, right }) {
  return (
    <Grid
      item
      xs={2}
      style={{
        color: positive
          ? AFFECT_CATEGORY_COLORS.positive
          : AFFECT_CATEGORY_COLORS.negative,
      }}
    >
      <Typography align={right ? "right" : "left"}>
        <strong>{Math.round(decimal * 100)}%</strong>
      </Typography>
    </Grid>
  );
}
