import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import Section from "../../common/background/Section";

import { TEXTURE } from "../../../config";

import AboutBanner from "../../../assets/about/overview_backgroundyellow.png"; 

function Introduction({ config }) {
  return (

    <Section margin="1rem" padding="1rem"> 
    <Grid item xs={12}>
      <Paper
        sx={{
          color: "black", 
          background: `url(${AboutBanner})`,
          backgroundSize: "cover",
          height: "100%",
          backgroundColor: "primary.main",
          marginTop: "-140px",
        }}
        elevation={0}
      >
        <Box
          marginTop={{ xs: 4, md: 6 }}
          marginBottom={{xs: 0}}
          marginX={{ xs: 3, md: 6 }}
          paddingY={{ xs: 2, md: 4}}
        >
          <Grid container>
            <Grid container padding={3}>
              <Grid item md={5}>
                <Typography variant="h5">{config.title}</Typography>
              </Grid>
              <Grid item md={7} paddingLeft={{ md: 3 }}>
                {config.body}
              </Grid>
            </Grid>
          </Grid>

          
        </Box>
      </Paper>
    </Grid>
    </Section> 
  );
}

export default Introduction;
