import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { Waypoint } from "react-waypoint";

import SummaryBlock from "./SummaryBlock";

function SummaryContainer({
  topicInfo,
  highlights,
  onEnterWaypoint,
  sectionId,
}) {
  const [selectedEmbedId, setSelectedEmbedId] = useState(topicInfo.defaultHid);
  const [selectedEmbedSection, setSelectedEmbedSection] = useState(
    topicInfo.defaultSection
  );

  useEffect(() => {
    setSelectedEmbedId(topicInfo.defaultHid);
    setSelectedEmbedSection(topicInfo.defaultSection);
  }, [topicInfo]);

  return (
    <Grid item xs={12}>
      {topicInfo.subTopics.map((subTopic) => (
        <Waypoint
          key={`waypoint-${sectionId}-${subTopic.label}`}
          onEnter={() => onEnterWaypoint(subTopic.label)}
          topOffset="30%"
          bottomOffset="60%"
        >
          <div>
            <SummaryBlock
              key={`summary-container-${sectionId}-${subTopic.label}`}
              title={subTopic.label}
              text={subTopic.text}
              link={subTopic.link}
              selectedEmbedId={selectedEmbedId}
              setSelectedEmbedId={setSelectedEmbedId}
              selectedEmbedSection={selectedEmbedSection}
              setSelectedEmbedSection={setSelectedEmbedSection}
              primaryColor={topicInfo.primaryColor}
              secondaryColor={topicInfo.secondaryColor}
              highlights={highlights}
              sectionId={sectionId}
            />
          </div>
        </Waypoint>
      ))}
    </Grid>
  );
}

export default SummaryContainer;
