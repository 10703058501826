import { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Box } from "@mui/material";

const responsive = {
  0: { items: 1 },
  568: { items: 1 },
  1024: { items: 1.5 },
};

const createItems = (items, activeIndex, [handleClick]) => {
  let deltaX = 0;
  let difference = 0;
  const swipeDelta = 10;

  return items.map((item, i) => {
    return (
      <Box
        data-value={i + 1}
        key={`carousel-item-${i}`}
        className="item"
        onMouseDown={(e) => (deltaX = e.pageX)}
        onMouseUp={(e) => (difference = Math.abs(e.pageX - deltaX))}
        onClick={() => difference < swipeDelta && handleClick(i)}
        style={{ opacity: activeIndex === i ? "1" : "0.6" }}
      >
        {item}
      </Box>
    );
  });
};

const Carousel = ({ content, activeIndex, setActiveIndex }) => {
  const [items, setItems] = useState(
    createItems(content, activeIndex, [setActiveIndex])
  );

  const slideNext = () => {
    if (activeIndex < content.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  const slidePrev = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const syncActiveIndex = ({ item }) => setActiveIndex(item);

  useEffect(() => {
    setItems(createItems(content, activeIndex, [setActiveIndex]));
  }, [activeIndex, content]);

  return (
    <>
      <Grid
        item
        xs={12}
        marginBottom={2}
        marginRight={{ md: 6 }}
        sx={{
          display: "flex",
          justifyContent: "right",
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: "#FFF", paddingRight: "1rem" }}
        >
          {activeIndex + 1}/{items.length}
        </Typography>
        <ChevronLeft
          onClick={slidePrev}
          fontSize="small"
          style={{
            cursor: "pointer",
            color: activeIndex === 0 ? "#CCC" : "#FFF",
          }}
        />
        <ChevronRight
          onClick={slideNext}
          fontSize="small"
          style={{
            cursor: "pointer",
            color: activeIndex === items.length - 1 ? "#CCC" : "#FFF",
          }}
        />
      </Grid>
      <AliceCarousel
        mouseTracking
        disableButtonsControls
        disableDotsControls
        items={items}
        activeIndex={activeIndex}
        responsive={responsive}
        controlsStrategy="alternate"
        onSlideChanged={syncActiveIndex}
      />
    </>
  );
};

export default Carousel;
