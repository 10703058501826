import { useState } from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import { Play, Pause } from "react-feather";
import Divider from "@mui/material/Divider";
import JsxParser from "react-jsx-parser";

import AudioPlayer from "./AudioPlayer";

import { trackThemeMedleyPlay } from "../../../tracking/utils";
import Medleys from "../../../assets/medleys/medleys"; 



//how do I make background color dynamic??? ugh. 
const Medley = styled.label`
  padding-left: 2rem;
  font-family: "Montserrat";
  font-size: 16px;
  text-transform: none;
  font-weight: 700;
  padding: 8px;
  background: ${props => props.buttonColor || '#ffbb00'}; 
  cursor: pointer;
  margin-bottom: 0.5rem;
  margin-right: 1rem; 
  line-height: 3; 

`;

//next steps: need to adjust summarymedley so that it is aligned with current portal data structure/json. 

 
function SummaryMedley({
  themeInfo,
  selectedSubTheme,
  setSelectedSubTheme,
  selectedEmbedId,
  setSelectedEmbedId,
  colorByTheme,
  medleyByTheme,
  primaryColor, 
  secondaryColor,
}) {


  const [isPlaying, setIsPlaying] = useState(false);


  const handleClick = () => {
    const play = !isPlaying;
    setIsPlaying(play);
    if (play) {
      trackThemeMedleyPlay(themeInfo.title.toLowerCase());
    }
  };

  const themeMedley = Medleys.find(
    (medley) => medley.title.toLowerCase() === themeInfo.toLowerCase()
  );


  return (
    <Col>
          <Medley onClick={handleClick} buttonColor={secondaryColor} >
            {isPlaying ? <Pause fill size={18} /> : <Play fill size={18} />}{" "}
            {themeMedley.time} min
          </Medley>
          <span className="ps-3">
            Listen to a medley on {themeInfo}
          </span>
          {isPlaying && (
            <AudioPlayer
              title="temp title"
              audioSrc={themeMedley.audioSrc}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
            />
          )}
         
    </Col>
  );
}

export default SummaryMedley;
