import Step1 from "../../../assets/about/processill_1.png";
import Step2 from "../../../assets/about/processill_2.png";
import Step3 from "../../../assets/about/processill_3.png";

import Listening from "../../../assets/about/lvn.png";
import ListeningVideo from "../../../assets/about/Process_Listening.mp4";
import Analyzing from "../../../assets/about/insights.png";
import AnalyzingVideo from "../../../assets/about/Process_Analyzing.mp4";
import Synthesizing from "../../../assets/about/process_synthesizing.png";

export const INTRODUCTION = {
  "title": "How does this work?",
  "body": "Every three years, JA Worldwide develops the strategic plan that guides our growth and priorities. For the first time, JA Worldwide invited young people to add their voices as part of an advisory group and share their JA experiences. JA Worldwide has partnered with Cortico and the MIT Center for Constructive Communication to provide tools to highlight, analyze, and share these conversations."
}

export const STEPS = [
  {
    "title": "Facilitated Conversations",
    "picture": Step1,
    "body": "<p> We start exploring this space by inviting community members, who are often underheard, to join a facilitated conversation with 4-8 participants (in-person or on Zoom). </p> <p> Ιn these conversations, we invite participants to share: “What is your question about the future of Boston and your place in that future? What experience led you to that question?”.</p> <p>  These prompts serve as starting points for participants to share hopes, concerns, and lived experiences and engage with each other in identifying common hopes and concerns and experiences that resonate with them. </p>" ,
    "nosections": "yeah", 
    "pections": [
      {
        "label": "JA Experiences",
        "text": "Reflect on your current experience with JA; tell us how the pandemic has shaped your experience as a learner."
      },
      {
        "label": "Community Supports",
        "text": "These times have been difficult for many.",
        "list": ["How has your community been affected?", "To what extent has your community received the supports that it needed?"],
        "bullet": true
      },
      {
        "label": "Future Visions",
        "text": "Close your eyes and picture your future 5 or 10 years from now.",
        "list": ["What do you hope it looks like?", "What challenges do you foresee?", "Which tools/skills did you develop as a JA member/alumni that can help you achieve your goals in that future?"],
        "bullet": true
      },
      {
        "label": "Takeaways",
        "text": "For our last question, we invite you to share one thing you heard today that you’ll be taking away from this conversation and that you’d like other people to hear."
      }
    ]
  },
  {
    "title": "Sensemaking: Listening to, analyzing, and synthesizing conversations",
    "picture": Step2,
    "body": "<p> How might we…</p> <ul> <li> Collect rich and complex data from community dialogues, analyze and make sense of that data? </li> <li> Design outputs of that sensemaking in a participatory way? </li> <li> Engage researchers and community leaders to exchange knowledge and learn together? </li> </ul> <p> These are some of the questions we address in this project that builds on community-powered understanding and participatory sensemaking. Our sensemaking process evolves over three interconnected stages: </p> ",
    "sections": [
      {
        "label": "Listening",
        "video": ListeningVideo, 
        "picture": Listening,
        "text": "We start by listening deeply to all conversations and immerse ourselves into the conversation collection. As we listen, we systematically identify and surface the themes that participants shared in the facilitated conversations."
      },
      {
        "label": "Analyzing",
        "video": AnalyzingVideo, 
        "picture": Analyzing,
        "text": "After we listen, we use our Sensemaking Tool Insight to analyze all selected segments of the conversations. We identify them as questions and experiences, following the prompts that are shared during the facilitated conversations. We then start to develop thematic codes and apply the codes to each conversation segment. As we listen to more conversations, we iteratively keep developing thematic codes and structure them into sub-themes that provide a more nuanced understanding of what is discussed during the conversations and overarching themes that consist of clusters of sub-themes.  Our goal is to identify themes and patterns across those experiences and design diverse ways of exploring the conversations by theme or sub-theme, neighborhood, or specific demographics. Beyond people-power alone, this project is supported and heightened with the help of different tools. Our process is designed to be supported and enabled by the unique collaboration between old, ancient, human technologies like facilitation, dialogue, and active listening with new, computer-enabled technology that allows us to analyze the transcribed conversations systematically and at scale. This unique collaboration allows for the depth and richness that comes from human technologies, with the scale, replicability, memory, and transparency of new tech.", 
        "list": [""],
        "bullet": false
      },
      {
        "label": "Synthesizing",
        "picture": Synthesizing,
        "text": "After our analysis, we provide context for each theme by summarizing what we heard in the conversations and translating themes into patterns. We weave into the thematic summaries representative highlights of the conversations to provide more nuanced insights into each theme and lift up participants’ voices who reflect on their lived experiences. Interested in learning more about our approach? Contact us at realtalk-info@media.mit.edu.",
        "small": false
      }
    ],
    "label": "Step"
  },
  {
    "title": "Conversation Portal",
    "picture": Step3,
    "body": "<p>Finally, your voices will be shared on the live conversation portal with Boston’s mayoral and city councilor candidates, debate moderators and journalists, and the general public. Boston’s mayoral and city councilor candidates will be invited to use the portal to better understand the hopes, concerns, and experiences of the city’s voters. Conversations will be used to inform recommended questions for debate moderators and media interviews. Finally, the platform will be made available to the public to listen and learn from one another.</p>"
  }
]