import { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Helmet } from 'react-helmet-async';

import StyledBanner from "../../common/banners/StyledBanner";
import HeaderAndMedia from "../../common/text/HeaderAndMedia";
import NewsPiece from "../Media/NewsPiece"
import NewsPieceMobile from "./NewsPieceMobile"; 

import MediaBanner from "../../../assets/about/mediabanner.png"; 


import { OVERVIEW } from "../config/config";
import { NEWSPIECES } from "../Media/config";

const SECTION = "media";

function Media() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const section = OVERVIEW.cards.find((card) => card.link === SECTION);

  return (
    <div style={{ background: "#FDF7F2"}}>
      <Helmet>
       <title>About Page / Media </title>
       <meta name='description' content='About Page / Media' />
     </Helmet>

      <StyledBanner
        title={section.title}
        picture={MediaBanner}
        navigation={OVERVIEW}
        light 
      />


      {NEWSPIECES.news.map((news) =>  
        <NewsPiece config={news} />
      )}

      {NEWSPIECES.news.map((news) =>  
        <NewsPieceMobile config={news} />
      )}
      
      
    </div>
  );
}

export default Media;
