import Logo from "./assets/logos/RealTalk_Logo-01.png";
import LogoSmall from "./assets/logos/rtfcfavicon2.svg";
import MapBackground from "./assets/textures/rtfcdark.png";
import BannerImg from "./assets/textures/rtfcmural.png";

export const ORGANIZATION = {
  name: "Real Talk for Change",
  homepage: "https://www.realtalkforchange.org/",
  logo: Logo,
  small: LogoSmall,
  linkBack: false,
};

// label is used when the map is referenced
export const MAP = {
  label: "Boston neighborhood",
  demographicId: "neighborhood",
};

export const BRAND = {
  headerFontFamily: "Montserrat",
  defaultHighlightColor: "#CBECEE",
  primaryDemographic: "neighborhood",
  secondaryDemographic: "",
  footerDemographic: "",
};

export const THEME = {
  palette: {
    primary: {
      main: "#FFBB00",
      dark: "#1A1A1A",
      light: "#E0D1C5",
    },
    secondary: {
      main: "#DF4600",
      dark: "#00A679",
      light: "#FDF7F2",
    },
  },
  typography: {
    h4: {
      fontFamily: BRAND.headerFontFamily,
      fontWeight: 800,
      fontSize: 32,
      paddingBottom: "1rem",
      letterSpacing: "0.25px",
    },
    h5: {
      fontFamily: BRAND.headerFontFamily,
      fontWeight: 650,
      fontSize: 28,
      paddingBottom: "1rem",
    },
    h6: {
      fontFamily: BRAND.headerFontFamily,
      fontWeight: 600,
      fontSize: 20,
      letterSpacing: "0.15px",
    },
    body1: {
      fontFamily: BRAND.headerFontFamily,
      fontSize: 16,
      letterSpacing: "0.15px",
    },
    body2: {
      fontFamily: BRAND.headerFontFamily,
      fontSize: 14,
      letterSpacing: "0.15px",
    },
    link1: {
      fontFamily: BRAND.headerFontFamily,
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: "0.15px",
    },
    link2: {
      fontFamily: BRAND.headerFontFamily,
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: "0.1px",
    },
    caption: {
      fontFamily: BRAND.headerFontFamily,
      fontSize: 12,
      letterSpacing: "0.4px",
    },
    subtitle1: {
      fontFamily: BRAND.headerFontFamily,
    },
    subtitle2: {
      fontFamily: BRAND.headerFontFamily,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: BRAND.headerFontFamily,
          fontWeight: 700,
          textTransform: "capitalize",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: BRAND.headerFontFamily,
          textTransform: "capitalize",
        },
      },
    },
  },
};

export const TEXTURE = MapBackground;
export const BANNERIMG = BannerImg;
