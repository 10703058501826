import Grid from "@mui/material/Grid";
import { USE_AVATAR } from "./config/config";

import AvatarHighlight from "./Avatar/Highlight";
import AvatarHighlightCompact from "./Avatar/HighlightCompact";
import PlainHighlight from "./Plain/Highlight";

function HighlightWrapper({
  sectionId,
  highlight,
  onSelect,
  selectedHighlight,
  extend,
  compact,
  scrolling,
  light,
}) {
  if (USE_AVATAR) {
    return (
      <>
        <Grid
          sx={{
            display: {
              xs: "none",
              sm: compact ? "none" : "block",
              md: "block",
            },
          }}
        >
          <AvatarHighlight
            sectionId={`${sectionId}-normal`}
            highlight={highlight}
            onSelect={onSelect}
            selectedHighlight={selectedHighlight}
            extend={extend}
            scrolling={scrolling}
            light={light}
          />
        </Grid>
        <Grid
          sx={{
            display: {
              xs: "block",
              sm: compact ? "block" : "none",
              md: "none",
            },
          }}
        >
          <AvatarHighlightCompact
            sectionId={`${sectionId}-compact`}
            highlight={highlight}
            onSelect={onSelect}
            selectedHighlight={selectedHighlight}
            scrolling={scrolling}
            extend={extend}
            light={light}
          />
        </Grid>
      </>
    );
  } else {
    return (
      <PlainHighlight
        sectionId={sectionId}
        highlight={highlight}
        onSelect={onSelect}
        selectedHighlight={selectedHighlight}
        extend={extend}
        scrolling={scrolling}
        light={light}
      />
    );
  }
}

export default HighlightWrapper;
