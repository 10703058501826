import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

export default function InfoModalButton({
  title,
  icon,
  buttonLabel,
  children,
  defaultOpen
}) {
  const [open, setOpen] = useState(defaultOpen);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        sx={{ color: "white", padding: 0 }}
        onClick={() => setOpen(true)}
      >
        <Typography variant="body2">
          {icon}
        </Typography>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" autoFocus>
            {buttonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
