import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import * as d3 from "d3";
import ReactTooltip from "react-tooltip";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

import Locations from "../../../assets/content/neighborhood_paths.json";
import {
  calculateHighlightCountByLocation,
  muteColor,
} from "../../../utils/utils";
import Speakers from "../../../assets/content/speakers.json";

const SVG = styled.svg`
  width: 100%;
  height: ${(props) => (props.noHeight ? "unset" : "80vh")};
`;



function InteractiveMap({
  currLocation,
  locations,
  highlights,
  onClick,
  onClose,
  noHeight,
  filteredHighlightsByTopic,
  locationType,
}) {
  const svgRef = useRef(null);

  let instruction = (
    <Typography variant="caption" fontWeight={600} color="text.secondary">
      Click on a {locationType} to learn more.
    </Typography>
  );

  if (currLocation) {
    instruction = (
      <Button onClick={onClose}>
        <Typography variant="caption">
          <CloseIcon
            sx={{
              fontSize: "14px",
              paddingRight: "0.5rem",
              verticalAlign: "middle",
            }}
          />
          Back to All {locationType}s
        </Typography>
      </Button>
    );
  }

  useEffect(() => {
    const draw = (x, y) => {
      const locationsWithCount = getHighlightCountByLocation(
        filteredHighlightsByTopic ?? highlights
      );

      const svg = d3.select(svgRef.current);
      svg.select("g").remove();
      svg.append("g").attr("transform", `translate(${x},${y})`);

      svg
        .select("g")
        .selectAll("g")
        .data(locationsWithCount)
        .join("path")
        .attr("fill", (d) => {
          const color =
            locations.find((location) => location.label === d.label)?.color ??
            "#CCC";

          if (!currLocation || d.label === currLocation) {
            return color;
          } else {
            return d.hide ? color : muteColor(color, true);
          }
        })
        .attr("d", (d) => d.path)
        .style("pointer-events", (d) => {
          if (d.hide) return "none";
          else return "auto";
        })
        .attr("data-tip", (d) => {
          if (d.hide || d.label === currLocation) {
            return "";
          }
          const label = `<b>View ${d.label}</b><br/>`;
          if (d.count > 0) {
            return `${label}${d.count} highlights`;
          }
          return `${label}No highlights`;
        })
        .attr("data-for", "map-tooltip")
        .on("click", (e, d) => {
          if (d.hide) return;

          onClick(d.label);
          ReactTooltip.hide();
        });
    };

    draw(0, 0);
    ReactTooltip.rebuild();
  }, [currLocation, highlights, locations, onClick]);

  return (
    <Grid>
      <Grid item className="map">
        <SVG
          ref={svgRef}
          preserveAspectRatio="xMidYMid meet"
          viewBox={`-300 0 1000 800`}
          noHeight={noHeight}
        />
        <ReactTooltip
          id="map-tooltip"
          delayHide={250}
          effect="float"
          html
          type="light"
        />
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: { xs: -4, md: noHeight ? "-5rem" : "-10rem" },
        }}
      >
        {instruction}
      </Grid>
    </Grid>
  );
}

const getHighlightCountByLocation = (highlights) => {
  const total = highlights.length;
  const countByLocation = calculateHighlightCountByLocation(
    Speakers,
    highlights
  );
  return Locations.map((locationInfo) => {
    return {
      ...locationInfo,
      total,
      count: countByLocation[locationInfo.label.toLowerCase()] ?? 0,
    };
  });
};

export default InteractiveMap;
