import CardTab from "./CardTab";
import Grid from "@mui/material/Grid";

import ArrowDown from "@mui/icons-material/ArrowDropDown";

function CardTabs({
  items,
  incrementLabel,
  selectedIdx,
  onClick,
  padding,
  small
}) {
  return (
    <Grid paddingX={padding ? 8 : 0} container sx={{ zIndex: "100" }}>
      <Grid
        container
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          zIndex: "100",
        }}
      >
        {items.map((item, idx) => {
          return (
            <Grid item xs={3} sx={{ height: "100%" }}>
              <CardTab
                idx={idx + 1}
                label={incrementLabel ? `${incrementLabel} ${idx + 1}` : ""}
                body={item.label}
                selectedIdx={selectedIdx}
                onClick={onClick}
                primaryColor={item.primaryColor}
                secondaryColor={item.secondaryColor}
                small={small}
              />
              {selectedIdx - 1 === idx && (
                <Grid
                  item
                  sx={{
                    marginTop: "-28px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ArrowDown
                    sx={{
                      color: "white",
                      fontSize: "65px",
                    }}
                  />
                </Grid>
              )}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

export default CardTabs;
