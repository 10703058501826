import Grid from "@mui/material/Grid";
import { Row, Col } from "react-bootstrap"; 

import SummaryMedley from "./SummaryMedley";

function SummaryMedleyWrapper({
  selectedTheme,
  selectedSubTheme,
  setSelectedSubTheme,
  themes,
  selectedEmbedId,
  setSelectedEmbedId,
  colorByTheme,
  medleyByTheme,
  primaryColor, 
  secondaryColor, 
}) {

  return (
    <Grid container mt={4} mb={1}>
      <Grid item className="overflow-scroll theme-summary-wrapper">
          <SummaryMedley
            themeInfo={selectedTheme}
            colorByTheme={colorByTheme}
            medleyByTheme={medleyByTheme}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
          />
      </Grid>
    </Grid>
  );
}

export default SummaryMedleyWrapper;