import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";

function Callout({ children, sx, backgroundColor, offset }) {
  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          ...sx,
          backgroundColor: backgroundColor ?? "unset",
          marginTop: offset ? offset : "unset",
        }}
        elevation={0}
      >
        <Box
          marginY={{ xs: 4, md: 6 }}
          marginX={{ xs: 3, md: 6 }}
          paddingY={{ xs: 2, md: 4}}
        >
          {children}
        </Box>
      </Paper>
    </Grid>
  );
}

export default Callout;
