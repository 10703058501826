import { useState, useEffect } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { HighlightPlayer } from "lvn-embed-api";

import Footer from "../Footer";
import Speaker from "../Speaker";
import PlayButton from "./PlayButton";
import Transcript from "../Transcript";
import TopicChips from "../TopicChips";
import {
  formatDuration,
  getSpeaker,
  getSpeakerInfo,
} from "../../../utils/utils";
import { BRAND } from "../../../config";
import Speakers from "../../../assets/content/speakers.json";

import { trackAudioPlayed } from "../../../tracking/utils"; 
import useAnalyticsEventTracker from "../../../tracking/utils";


function Highlight({
  sectionId,
  highlight,
  onSelect,
  selectedHighlight,
  extend,
  scrolling,
  light,
}) {
  const [minimalPlayer, setMinimalPlayer] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [displayTime, setDisplayTime] = useState(highlight.duration);

  const handlePlayerEnded = () => setIsPlaying(false);
  const handlePlayerTimeUpdate = (e) => {
    setDisplayTime(e.seconds);
  };
  const handlePlayerReady = (player) => {
    player.on("ended", handlePlayerEnded);
    player.on("timeupdate", handlePlayerTimeUpdate);
  };

  var promptLabel=""; 
  if (highlight.prompt=="question") 
  {
    promptLabel= "Prompt 1/5: What’s your question about the future of Boston and your place in it?"; 
  } else if (highlight.prompt=="experience") {
    promptLabel= "Prompt 2/5: Share an experience that shaped your question about Boston's future."; 
  } else if (highlight.prompt=="resonating experience") {
    promptLabel= "Prompt 3/5: Which of the experiences shared resonated with you?"; 
  } else if (highlight.prompt=="drawing connections") {
    promptLabel= "Prompt 4/5: What are you hearing from people's experiences?"; 
  } else if (highlight.prompt=="takeaway") {
    promptLabel= "Prompt 5/5: What are you taking away from the conversation?"; 
  }

  useEffect(() => {
    setMinimalPlayer(
      new HighlightPlayer({
        containerId: `${sectionId}-embed-minimal-${highlight.id}`,
        highlightId: highlight.id,
        highlightOptions: {
          type: "minimal",
          fontSize: "16px",
          scrolling: scrolling,
          fontFamily: BRAND.headerFontFamily,
          fontWeight: "500",
          highlightColor: BRAND.defaultHighlightColor,
        },
        onReady: handlePlayerReady,
      })
    );
  }, [sectionId, highlight, scrolling]);

  useEffect(() => {
    if (selectedHighlight !== highlight.id) {
      if (minimalPlayer) {
        minimalPlayer.pause();
        setIsPlaying(false);
      }
    }
  }, [selectedHighlight]);


  const handlePlayToggle = () => {
    if (minimalPlayer) {
      if (minimalPlayer.getIsPlaying()) {
        minimalPlayer.pause();
      } else {
        minimalPlayer.play();
        trackAudioPlayed(); 
      }
      setIsPlaying(minimalPlayer.isPlaying);

      if (onSelect) onSelect(highlight.id);
    }
  };

  return (
    <>
      <Card elevation={1} sx={{ width: "100%", borderRadius: "5px" }}>
        <CardContent>
          <Grid container sx={{ paddingRight: ".5rem", paddingTop: ".5rem" }}>
            <Grid item md={1.25} sm={12} paddingRight={".5rem"}>
              <Box>
                <PlayButton
                  duration={`${formatDuration(displayTime)}`}
                  isPlaying={isPlaying}
                  onClick={handlePlayToggle}
                />
              </Box>
            </Grid>
            <Grid container item md={10.75} sm={12}>
              <Grid item xs={12} paddingLeft={2} paddingBottom={2.5}>
                <Grid container>
                  <Grid item md={11}>
                    <Speaker
                      speaker={highlight.speaker}
                      primary={getSpeakerInfo(
                        BRAND.primaryDemographic,
                        Speakers,
                        highlight
                      )}
                      secondary={getSpeakerInfo(
                        BRAND.secondaryDemographic,
                        Speakers,
                        highlight
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    md={1}
                    sx={{ display: "flex", justifyContent: "right" }}
                  >
                    <Typography variant="caption" color="text.secondary">
                      {formatDuration(highlight.duration)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Transcript
                sectionId={sectionId}
                hid={highlight.id}
                extend={extend}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Footer // want to make extraInfo the Prompt!! Info
          extraInfo={
            promptLabel
          }
        />
      </Card>
      <TopicChips affecttags={highlight.community_affect} tags={highlight.tags} light={light} />
    </>
  );
}

export default Highlight;
