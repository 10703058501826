import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import Section from "../../common/background/Section";
import InfoCard from "../../common/cards/InfoCard"

import { TEXTURE } from "../../../config";

import AboutBanner from "../../../assets/about/overview_backgroundyellow.png"; 

function NewsPiece({ config }) {



  return (

    

    <Section margin="0" padding="0">
      <Paper
        sx={{
          color: "black", 
          backgroundColor: "white",
          display: {xs: 'none', sm: 'none', md: 'block'}
        }}
        elevation={0}
      >
          <Grid container marginTop={{sm: 4, md: 10}} marginBottom={{sm: -10, md: 0}}
          sx={{
          color: "black", 
          backgroundColor: "white",
          display: {xs: 'none', sm: 'none', md: 'block'}
        }}>
            <Grid container>
              <Grid item sm={12} md={5} paddingX={5} paddingY={5}>
                    <InfoCard
                      title={config.title}
                      link={config.link}
                      linkLabel={config.linktitle}
                      link2={config.link2}
                      link2Label={config.link2title}
                      external="true"
                    >
                    
                      <p> <strong> {config.source} </strong> | {config.date} </p> 
                      <p> {config.description} </p> 
                      

                    </InfoCard> 
               
              </Grid>
              <Grid item sm={12} md={7} paddingLeft={{ md: 3 }}
              sx={{
                background: `url(${config.picture})`,
                backgroundSize: "cover", 
              }}>
              </Grid>
            </Grid>
          </Grid>
      </Paper>
    </Section> 

  );
}

export default NewsPiece;
