import { Grid, Typography } from "@mui/material";

const ProcessStep = ({ children, imageSrc, step, title }) => {
  return (
    <Grid container spacing={3} paddingX={3}>
      <Grid
        item
        md={6}
        sm={8}
        xs={12}
        sx={{ display: { xs: "none", sm: "block" } }}
      >
        <Typography variant="h3" color="primary.main">
          {step}
        </Typography>
        <Typography variant="h5" color="primary.dark">
          {title}
        </Typography>
        {children}
      </Grid>
      <Grid container item md={6} sm={4} xs={12} justifyContent={{sm: "center", md: "flex-end"}}>
        <img src={imageSrc} alt="" style={{ height: "125px" }} />
      </Grid>
      <Grid
        item
        md={6}
        sm={8}
        xs={12}
        sx={{ display: { xs: "block", sm: "none" } }}
      >
        <Typography variant="h4" color="primary.main">
          {step}
        </Typography>
        <Typography variant="h5" color="primary.dark">
          {title}
        </Typography>
        {children}
      </Grid>
    </Grid>
  );
};

export default ProcessStep;
