import { Grid, Typography } from "@mui/material";
import { Helmet } from 'react-helmet-async';

import LocationOverviewContainer from "./LocationOverview/LocationOverviewContainer";
import TopicsOverviewContainer from "./TopicsOverview/TopicsOverviewContainer";
import TopicsOverviewContainer2 from "./TopicsOverview/TopicsOverviewContainer2"
import HighlightOverviewContainer from "./HighlightOverview/HighlightOverviewContainer";
import ParticipantOverviewContainer from "./ParticipantOverview/ParticipantOverviewContainer";
import StructureOverviewContainer from "./StructureOverview/StructureOverviewContainer";
import IntroVideo from "../About/Process/IntroVideo"; 

import Callout from "../common/callouts/Callout";
import HeaderAndMedia from "../common/text/HeaderAndMedia";
import CenterHeader from "../common/text/CenterHeader";
import SplitBanner from "../common/banners/SplitBanner";
import StyledBanner from "../common/banners/StyledBanner";



import {
  LANDING,
  HIGHLIGHT,
  TOPIC,
  PARTICIPANT,
  LOCATION,
  CTA,
  STRUCTURE,
} from "./config/config";

function Landing({ topics, prompts, highlights }) {
  return (
    <Grid container sx={{ mt: 0}}>
      <Helmet>
        <title>Real Talk for Change</title>
        <meta name='description' content='Real Talk for Change Portal Landing' />
      </Helmet>
      <SplitBanner 
        title={LANDING.title}
        subtitle={LANDING.subtitle}
        body={LANDING.description}
        picture={LANDING.picture}
        light
        large
      /> 

      <IntroVideo /> 

      <HeaderAndMedia
            title={CTA.title}
            body={CTA.body}
            body2={CTA.body2}
            link={CTA.link}
            media={CTA.media}
            transparent
            swap
            margin="0px"
            padding="0px"
      >
      </HeaderAndMedia>


      <Grid
        container
        item
        marginTop={{ sm: 2, md: 0 }}
        marginBottom={{ sm: 0, md: 0 }}
      >
        <Callout offset>
          <TopicsOverviewContainer topics={topics} config={TOPIC} margin highlights={highlights}/> 
        </Callout>
      </Grid>
 

      <Grid
        container
        item
        xs={12}
        marginTop={{ sm: 2, md: 2 }}
        marginBottom={{ xs: 6, md: 16 }}
      >

        <HighlightOverviewContainer topics={prompts} config={STRUCTURE} />

      </Grid>
    
  

      <Grid
        container
        item
        marginTop={{ sm: 2, md: 2 }}
        marginBottom={{ sm: 2, md: 8 }}
      >
        <Callout offset="0">
          <LocationOverviewContainer config={LOCATION} />
        </Callout>
      </Grid>



      
    </Grid>
  );
}

export default Landing;
