import LearningExperiences from "../../../assets/insights-page/learning-experiences-aqua.png";
import Values from "../../../assets/insights-page/values-aqua.png";
import YouthEmpowerment from "../../../assets/insights-page/youth-empowerment-aqua.jpg";
import SelfEfficacy from "../../../assets/insights-page/self-efficacy-aqua.png";
import ThrivingCommunities from "../../../assets/insights-page/thriving-communities-aqua.jpg";
import Impact from "../../../assets/insights-page/impact-aqua.jpg";

import Housing from "../../../assets/insights-page/housing-tinted.png";
import GovernmentInstitutions from "../../../assets/insights-page/institutions-tinted.png";
import PublicHealth from "../../../assets/insights-page/publichealth-tinted.png";
import Inequality from "../../../assets/insights-page/inequality-tinted.png";
import EconomicOpportunity from "../../../assets/insights-page/economicopportunity-tinted.png";
import Education from "../../../assets/insights-page/education-tinted.png";
import CommunityLife from "../../../assets/insights-page/communitylife-tinted.png";
import Infrastructure from "../../../assets/insights-page/infastructure-tinted.png";
import Safety from "../../../assets/insights-page/safety-tinted.png";
import LoaCta from "../../../assets/insights-page/ltacta-tinted.png";
import Default from "../../../assets/textures/bostonmaptexture_green.png";


import HousingLong from "../../../assets/insights-page/housing-tinted2.png";
import GovernmentInstitutionsLong from "../../../assets/insights-page/institutions-tinted2.png";
import PublicHealthLong from "../../../assets/insights-page/publichealth-tinted2.png";
import InequalityLong from "../../../assets/insights-page/inequality-tinted2.png";
import EconomicOpportunityLong from "../../../assets/insights-page/economicopportunity-tinted2.png";
import EducationLong from "../../../assets/insights-page/education-tinted2.png";
import CommunityLifeLong from "../../../assets/insights-page/communitylife-tinted2.png";
import InfrastructureLong from "../../../assets/insights-page/infastructure-tinted2.png";
import SafetyLong from "../../../assets/insights-page/safety-tinted2.png";

import HousingIcon from "../../../assets/topic-icons/icon-housing.png"; 
import GovernmentInstitutionsIcon from "../../../assets/topic-icons/icon-governmentandinstitutions.png"; 
import PublicHealthIcon from "../../../assets/topic-icons/icon-publichealth.png"; 
import InequalityIcon from "../../../assets/topic-icons/icon-inequality.png"; 
import EconomicOpportunityIcon from "../../../assets/topic-icons/icon-economicopportunity.png"; 
import EducationIcon from "../../../assets/topic-icons/icon-education.png"; 
import CommunityLifeIcon from "../../../assets/topic-icons/icon-communitylife.png"; 
import InfrastructureIcon from "../../../assets/topic-icons/icon-infastructure.png"; 
import SafetyIcon from "../../../assets/topic-icons/icon-safety.png"; 


import HousingMedley from "../../../assets/medleys/housing.mp3"; 
import GovernmentInstitutionsMedley from "../../../assets/medleys/governmentandinstitutions.mp3"; 
import PublicHealthMedley from "../../../assets/medleys/publichealth.mp3"; 
import InequalityMedley from "../../../assets/medleys/inequality.mp3"; 
import EconomicOpportunityMedley from "../../../assets/medleys/economicopportunity.mp3"; 
import EducationMedley from "../../../assets/medleys/education.mp3"; 
import CommunityLifeMedley from "../../../assets/medleys/communitylife.mp3"; 
import InfrastructureMedley from "../../../assets/medleys/infrastructure.mp3"; 
import SafetyMedley from "../../../assets/medleys/safety.mp3"; 



// positive and negative colors
export const AFFECT_CATEGORY_COLORS = {
  positive: "#00BFA6",
  negative: "#FF1744",
};

export const OVERVIEW = {
  title: "Insights",
  link: "/insights",
  body: "During Real Talk conversations, community members voiced many of the same concerns. In this section, we share common questions and experiences from across the city.",
  banners: {
    default: {
      src: Default,
    },
    housing: {
      src: Housing,
      src2: HousingLong,
      icon: HousingIcon, 
      medley: HousingMedley, 
    },
    "government-and-institutions": {
      src: GovernmentInstitutions,
      src2: GovernmentInstitutionsLong,
      icon: GovernmentInstitutionsIcon, 
      medley: GovernmentInstitutionsMedley, 
    },
    "public-health": {
      src: PublicHealth,
      src2: PublicHealthLong,
      icon: PublicHealthIcon, 
      medley: PublicHealthMedley, 
    },
    inequality: {
      src: Inequality,
      src2: InequalityLong,
      icon: InequalityIcon, 
      medley: InequalityMedley, 
    },
    "economic-opportunity": {
      src: EconomicOpportunity,
      src2: EconomicOpportunityLong,
      icon: EconomicOpportunityIcon, 
      medley: EconomicOpportunityMedley, 
    },
    education: {
      src: Education,
      src2: EducationLong,
      icon: EducationIcon, 
      medley: EducationMedley, 
    },
    "community-life": {
      src: CommunityLife,
      src2: CommunityLifeLong,
      icon: CommunityLifeIcon, 
      medley: CommunityLifeMedley, 
    },
    infrastructure: {
      src: Infrastructure,
      src2: InfrastructureLong,
      icon: InfrastructureIcon, 
      medley: InfrastructureMedley, 
    },
    safety: {
      src: Safety,
      src2: SafetyLong,
      icon: SafetyIcon, 
      medley: SafetyMedley, 
    },
    loacta: {
      src: LoaCta,
    },
    "self-efficacy": {
      src: SelfEfficacy,
    },
    impact: {
      src: Impact,
    },
    "learning-experiences": {
      src: LearningExperiences,
    },
    "thriving-communities": {
      src: ThrivingCommunities,
    },
    "youth-empowerment": {
      src: YouthEmpowerment,
    },
    values: {
      src: Values,
    },
  },
};
