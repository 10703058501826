import { useState, useEffect } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { HighlightPlayer } from "lvn-embed-api";

import Avatar from "./Avatar";
import Footer from "../Footer";
import Speaker from "../Speaker";
import PlayButton from "./PlayButton";
import Transcript from "../Transcript";
import TopicChips from "../TopicChips";
import {
  formatDuration,
  getSpeaker,
  getSpeakerImgSrc,
  getSpeakerInfo,
} from "../../../utils/utils";
import { BRAND } from "../../../config";
import Speakers from "../../../assets/content/speakers.json";

function HighlightCompact({
  sectionId,
  highlight,
  onSelect,
  selectedHighlight,
  extend,
  scrolling,
  light,
}) {
  const [minimalPlayer, setMinimalPlayer] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [displayTime, setDisplayTime] = useState(highlight.duration);

  const handlePlayerEnded = () => setIsPlaying(false);
  const handlePlayerTimeUpdate = (e) => {
    setDisplayTime(e.seconds);
  };
  const handlePlayerReady = (player) => {
    player.on("ended", handlePlayerEnded);
    player.on("timeupdate", handlePlayerTimeUpdate);
  };

  useEffect(() => {
    setMinimalPlayer(
      new HighlightPlayer({
        containerId: `${sectionId}-embed-minimal-${highlight.id}`,
        highlightId: highlight.id,
        highlightOptions: {
          type: "minimal",
          fontSize: "14px",
          scrolling: scrolling,
          fontFamily: BRAND.headerFontFamily,
          fontWeight: "500",
          highlightColor: BRAND.defaultHighlightColor,
        },
        onReady: handlePlayerReady,
      })
    );
  }, [sectionId, highlight]);

  useEffect(() => {
    if (selectedHighlight !== highlight.id) {
      if (minimalPlayer) {
        minimalPlayer.pause();
        setIsPlaying(false);
      }
    }
  }, [selectedHighlight]);

  const handlePlayToggle = () => {
    if (minimalPlayer) {
      if (minimalPlayer.getIsPlaying()) {
        minimalPlayer.pause();
      } else {
        minimalPlayer.play();
      }
      setIsPlaying(minimalPlayer.isPlaying);

      if (onSelect) onSelect(highlight.id);
    }
  };

  return (
    <>
      <Card elevation={1} sx={{ width: "100%", borderRadius: "5px" }}>
        <CardContent>
          <Grid container sx={{ paddingX: ".25rem", paddingTop: ".5rem" }}>
            <Grid item xs={2} paddingRight={".5rem"}>
              <Avatar src={getSpeakerImgSrc(Speakers, highlight)} />
            </Grid>
            <Grid container item xs={10}>
              <Grid item xs={12} paddingLeft={2} paddingBottom={1.5}>
                <PlayButton
                  info={highlight.speaker}
                  isPlaying={isPlaying}
                  onClick={handlePlayToggle}
                  small
                />
                <Box paddingTop={1}>
                  <Speaker
                    small
                    primary={getSpeakerInfo(
                      BRAND.primaryDemographic,
                      Speakers,
                      highlight
                    )}
                    secondary={getSpeakerInfo(
                      BRAND.secondaryDemographic,
                      Speakers,
                      highlight
                    )}
                  />
                  <Typography
                    component="span"
                    variant="caption"
                    sx={{ color: "gray" }}
                  >
                    {"   "}•{"   "}
                    {formatDuration(highlight.duration)}
                  </Typography>
                </Box>
              </Grid>
              <Transcript
                sectionId={sectionId}
                hid={highlight.id}
                extend={extend}
                compact={true}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Footer
          extraInfo={
            getSpeaker(Speakers, highlight)?.demographics[
              BRAND.footerDemographic
            ]
          }
        />
      </Card>
      <TopicChips tags={highlight.tags} light={light} />
    </>
  );
}

export default HighlightCompact;
