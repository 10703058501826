export function combineSnippetsWithSameSpeaker(entities) {
  const combinedSnippets = [];
  let lastSnippet;
  Object.values(entities.snippets).forEach((snippet) => {
    if (lastSnippet) {
      if (lastSnippet.speaker_id === snippet.speaker_id) {
        lastSnippet.audio_end_offset = snippet.audio_end_offset;
        lastSnippet.duration += snippet.duration;
      } else {
        combinedSnippets.push(lastSnippet);
        lastSnippet = { ...snippet };
      }
    } else {
      lastSnippet = { ...snippet };
    }
  });
  if (lastSnippet) {
    combinedSnippets.push(lastSnippet);
    lastSnippet = undefined;
  }

  return combinedSnippets;
}

export function toSnakeCase(str) {
  return (
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join("_")
  );
}

const tagToTitleObj = {
  self_efficacy: "Self-Efficacy",
  impact: "Impact",
  learning_experiences: "Learning Experiences",
  thriving_communities: "Thriving Communities",
  youth_empowerment: "Youth Empowerment",
  values: "Values",
};

export function tagToTitle(tag) {
  return tagToTitleObj[tag];
}