import * as d3 from "d3";

export function transl(x, y) {
  return `translate(${x},${y})`;
}

export function rot(a, x = 0, y = 0) {
  return `rotate(${a}, ${x}, ${y})`;
}

export function linebreak(s) {
  if (s.includes(BREAK))
    return s.split(BREAK).map(w => w.trim());
  else
    return s.split(" ");
}

export const BREAK = "<br/>";

export function slugify(s) {
  return s.replaceAll(BREAK, "").toLowerCase().replaceAll(/\s+/g, "-");
}

export function lighten(color) {
  let c = d3.hsl(color);
  return c.copy({ s: 0.5, l: 0.8 }).formatHex();
}

export function prettyCapitalize(s) {
  return s.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
}

export function extractTopic(info) {
  return info.id.split("::")[1];
}

export const isColorLight = (hexColor) => {
  // determine if the color is light or dark
  // from https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
  const red = parseInt(`${hexColor[1]}${hexColor[2]}`, 16);
  const green = parseInt(`${hexColor[3]}${hexColor[4]}`, 16);
  const blue = parseInt(`${hexColor[5]}${hexColor[6]}`, 16);
  return red * 0.299 + green * 0.587 + blue * 0.114 > 186;
}

export function isSubtopic(info) {
  return info.id.split("::").length === 3;
}