import styled from "styled-components";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const Container = styled.label`
  ${(props) =>
    props.selected
      ? `background: ${props.primaryColor}; color: white; padding-right: 6px; padding-left: 0px;`
      : `background: ${props.secondaryColor}; padding-right: 6px; padding-left: 0px;`}
  cursor: pointer;
  &:hover {
    ${(props) =>
      props.selected ? "" : `background: ${props.primaryColor}; color: black;`}
`;

function ZagatEmbed({ children, getEmbedInfo }) {

  const parts = children.props.children.split("-");
  const label = parts[0];
  const embedId = parts[1];

  const {
    selectedEmbedId,
    setSelectedEmbedId,
    embedSection,
    setSelectedEmbedSection,
    primaryColor,
    secondaryColor,
    popUp,
    setOpen,
  } = getEmbedInfo();

  const selected = parseInt(embedId) === parseInt(selectedEmbedId);

  const handleClick = (embedId) => {
    if (popUp) {
      setOpen(embedId);
    } else {
      setSelectedEmbedId(embedId);
      setSelectedEmbedSection(embedSection);
    }
  };

  return (
    <Container
      onClick={() => handleClick(embedId)}
      selected={selected}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
    >
      <PlayArrowIcon sx={{ fontSize: "14px", verticalAlign: "middle" }} />{" "}
      {label}
    </Container>
  );
}

export default ZagatEmbed;
