import React from "react"; 
import ReactGA from "react-ga"; 

/* testing acc to blog.saeloun.com */ 
const useAnalyticsEventTracker = (category="Blog category") => {
    const eventTracker = (action = "test action", label = "test label") => {
      ReactGA.event({category, action, label});
      console.log("test test test"); 
    }
    return eventTracker;
  }
export default useAnalyticsEventTracker;


/* Format from previous portal */ 
export const track = (category, label) => {
    window.gtag("event", category, {
      label,
    });
  };


 export const trackAudioPlayed = () => {
      track("portal.audio.play.clicked"); 
  }

  export const trackThemeMedleyPlay = (theme) => {
    track("portal.homepage.theme.medley.played", theme);
  };


 //audio medley is played


 // neighborhood map is interacted with


 //theme bubble is clicked. 




  /* 
  Events from Previous Portal. 
  export const trackFeedback = () => {
    track("portal.feedback.clicked");
  };
  
  export const trackJoinConversation = () => {
    track("portal.join-conversation.clicked");
  };
  
  export const trackMapClicked = (neighborhood) => {
    track("portal.map.neighborhood.clicked", neighborhood);
  };
  
  export const trackMapFiltered = (filterType, label) => {
    track(`portal.map.${filterType}.filtered`, label);
  };
  
  export const trackThemeExplorerThemeClicked = (theme) => {
    track("portal.theme-explorer.theme.clicked", theme);
  };
  
  export const trackSummaryMedleyPlay = (theme) => {
    track("portal.theme-explorer.theme.medley.played", theme);
  };
  
  export const trackVoiceExplorerNeighborhood = (neighborhood) => {
    track("portal.voice-explorer.filtered.neighborhood", neighborhood);
  };
  
  export const trackVoiceExplorerTheme = (theme) => {
    track("portal.voice-explorer.filtered.theme", theme);
  };
  
  export const trackVoiceExplorerStoryType = (storyType) => {
    track("portal.voice-explorer.filtered.story-type", storyType);
  };
  
  export const trackVoiceExplorerPage = (page) => {
    track("portal.voice-explorer.paged", page);
  };
  
  export const trackVoiceExplorerSort = (direction) => {
    track("portal.voice-explorer.sorted", direction);
  };
  
  export const trackVoiceExplorerDate = (dates) => {
    track(
      "portal.voice-explorer.sorted",
      dates.map((d) => d.toLocaleDateString("en-US")).join(" to "),
    );
  };
  
  export const trackAudioTagClicked = (theme) => {
    track("portal.audio.tag.clicked", theme);
  };
  
  export const trackQuestionExperienceToggle = (storyType) => {
    track("portal.audio.story-type.toggle", storyType);
  };

  */ 

