import JsxParser from "react-jsx-parser";
import Card from "@mui/material/Card";
import { Grid } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LinkButton from "../buttons/LinkButton";
import CommunityAffectBar from "../../TopicView/CommunityAffectBar";

export default function InfoCardTopicView({
  title,
  subTitle,
  picture,
  link,
  linkLabel,
  children,
  small,
  preview,
  transparent,
  light,
  stackedBarData,
  icon, 
}) {
  return (
    <Card
      elevation={0}
      sx={{ backgroundColor: transparent ? "unset" : "white" }}
    >
      {picture && <CardMedia sx={{ paddingTop: "40%" }} image={picture} />}
      <CardContent sx={{ padding: "1.5rem" }}>
        
        <Grid container wrap="nowrap" spacing={2}>
          {icon && 
          <Grid item> 
           <img src={icon} width="45px" /> 
          </Grid> 
          }
          <Grid item xs> 
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    color: light ? "white" : "primary.dark",
                    paddingBottom: ".5rem",
                  }}
                >
                  {title}
                </Typography>
          </Grid> 
        </Grid> 
     
        
 

        {subTitle && (
          <Typography
            gutterBottom
            variant={small ? "body2" : "body1"}
            component="div"
            color={light ? "white" : "primary.dark"}
            sx={{ fontWeight: "550" }}
          >
            {subTitle}
          </Typography>
        )}

        {stackedBarData && stackedBarData.length > 0 && (
          <CommunityAffectBar data={stackedBarData}></CommunityAffectBar>
        )}

        <Typography
          variant="body2"
          color={light ? "whitesmoke" : "text.secondary"}
          sx={{ paddingTop: "1rem" }}
        >
          {preview ? <JsxParser jsx={preview} /> : children}
        </Typography>
      </CardContent>
      {link && (
        <CardActions sx={{ padding: "1.5rem" }}>
          <LinkButton link={link} size={small ? "small" : "medium"}>
            {linkLabel}
          </LinkButton>
        </CardActions>
      )}
    </Card>
  );
}
