import { useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import TopicsViz from "./TopicsViz";
import DimensionCard from "../../TopicView/DimensionCard";
import InfoCard from "../../common/cards/InfoCard";
import ConversationModal from "../../VoicesView/ConversationView/ConversationModal";

import { extractTopic } from "./PackedCircles/d3-utils.js";

function TopicsOverviewContainer2({ topics, config, highlights }) {
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedSubTopic, setSelectedSubTopic] = useState(null);
  const [selectedEmbedId, setSelectedEmbedId] = useState(null);

  const handlePopupClose = () => {
    setSelectedEmbedId(null);
  };

  const getEmbedInfo = () => {
    return {
      selectedEmbedId: parseInt(selectedEmbedId),
      setSelectedEmbedId,
      primaryColor: "#A31F34",
      secondaryColor: "#F0DFE2",
      popUp: true,
      setOpen: setSelectedEmbedId,
    };
  };

  const handleSelection = (topic, subTopic) => {
    if (!subTopic) {
      setSelectedTopic(topic);
      setSelectedSubTopic(null);
    } else {
      setSelectedTopic(null);
      setSelectedSubTopic(null);
    }
  };

  const handleClose = () => {
    setSelectedEmbedId(null);
    setSelectedTopic(null);
    setSelectedSubTopic(null);
  };

  const topicId = selectedTopic ? extractTopic(selectedTopic) : null;
  const topicInfo = topics.find((topic) => topic.id === topicId);

  return (
    <>
      {selectedEmbedId && (
        <ConversationModal
          onClose={handlePopupClose}
          highlight={highlights.find((h) => h.id === parseInt(selectedEmbedId))}
        />
      )}
      <Grid
        container
        sx={{ border: "1px solid #E6E7E7", backgroundColor: "white" }}
      >
        <Grid item xs={12} md={6} marginTop={-1}>
          <center>
            <TopicsViz
              themeMetadata={topics}
              selected={selectedTopic}
              section={selectedSubTopic}
              onSelection={handleSelection}
              onClose={handleClose}
            />
          </center>
        </Grid>
        <Grid item xs={12} md={6} sx={{ backgroundColor: "primary.light" }}>
          <Box paddingTop={6} paddingBottom={6} paddingX={{ xs: 3, sm: 6 }}>
            {topicId && (
              <DimensionCard
                dimensionInfo={topicInfo}
                transparent
                search
                expandedText={topicInfo.extended}
                getEmbedInfo={getEmbedInfo}
                expandedOnly
                linkLabel="Explore Full Insight"
                hideSubtitle
                icon
              />
            )}
            {!topicId && (
              <InfoCard
                title={config.title}
                preview={config.body}
                link="/insights/values"
                linkLabel="Explore Values"
                transparent
                icon
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default TopicsOverviewContainer2;