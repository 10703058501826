import { useEffect } from "react";
import { OVERVIEW } from "./config/config";
import { Helmet } from 'react-helmet-async';

import InfoCardViewAbout from "../common/cards/InfoCardViewAbout";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
  <div> 
    <Helmet>
        <title>About Page</title>
        <meta name='description' content='RTFC Portal About Page' />
      </Helmet>
  <InfoCardViewAbout config={OVERVIEW} />
  </div> 
  )
}

export default About;
