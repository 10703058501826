import { Typography, Box, Grid } from "@mui/material";
import { ArrowDownward } from "@mui/icons-material";

import Banner from "./Banner";
import BreadcrumbLinks from "./BreadcrumbLinks";

import { TEXTURE } from "../../../config";

function StyledBanner({
  title,
  body,
  picture,
  navigation,
  fontColor,
  texture,
  light,
  large,
  arrow,
  icon, 
}) {
  let sx = {
    color: fontColor ?? (light ? "white" : "primary.dark"),
    backgroundColor: light ? "primary.dark" : "primary.light",
    minHeight: "40vh",
  };

  if (picture || texture) {
    sx = {
      ...sx,
      background: picture
        ? `url(${picture})`
        : texture
        ? `url(${TEXTURE})`
        : "unset",
      backgroundSize: "cover",
      minHeight: picture ? (large ? "60vh" : "55vh") : "40vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    };
  }

  console.log(icon); 

  return (
    <Banner sx={sx}>
      <Box>
        {navigation && (
          <Box>
            <BreadcrumbLinks
              navigation={navigation}
              color="inherit"
            />
          </Box>
        )}


        <Grid container wrap="nowrap" spacing={2}>
          {icon && 
          <Grid item> 
           <img src={icon} width="45px" /> 
          </Grid> 
          }
          <Grid item xs> 
                <Typography
                  variant="h4"
                  component="div"
                  sx={{
                    color: light ? "white" : "primary.dark",
                    paddingBottom: "1.5rem",
                  }}
                >
                  {title}
                </Typography>
          </Grid> 
        </Grid> 

        {body && (
          <Typography variant="h6" sx={{ fontWeight: 550 }}>
            {body}
          </Typography>
        )}
        {arrow && (
          <Typography sx={{ paddingTop: "2rem", marginBottom: "6rem" }}>
            <ArrowDownward />
          </Typography>
        )}
      </Box>
    </Banner>
  );
}

export default StyledBanner;
