import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Stack, Container } from "@mui/material";
import DropdownMenu from "../common/menus/DropdownMenu";

import Sticky from "react-sticky-el";


const JumpLink = styled("a")((props) => ({
  color: props.fontColor,
  textDecoration: "none",
  fontSize: "14px",
}));

function TopicNav({
  topics,
  onClick,
  onChange,
  currSection,
  primaryColor,
  secondaryColor,
}) {
  const chipData = topics.map((topic) => ({
    key: topic.link,
    label: topic.label,
  }));

  return (
    <Sticky>
      <Grid
        container
        item
        xs={12}
        sx={{
          paddingY: "1rem",
          backgroundColor: "primary.light",
        }}
      >
        <Container maxWidth="lg">
          <Stack
            direction="row"
            spacing={5}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            {chipData.map((data) => (
              <JumpLink
                key={data.key}
                href={`#screen-${data.key}`}
                onClick={() => onClick(data.label)}
                fontColor={
                  currSection === data.label
                    ? "black"
                    : secondaryColor ?? "black"
                }
                sx={{
                  fontWeight: currSection === data.label ? "700" : "500",
                }}
              >
                {data.label}
              </JumpLink>
            ))}
          </Stack>
          <Grid
            paddingX={{ xs: 3, sm: 6 }}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <DropdownMenu
              items={topics}
              selected={currSection}
              label="Current Section"
              handleChange={onChange}
            />
          </Grid>
        </Container>
      </Grid>
    </Sticky>
  );
}

export default TopicNav;
