/* import Landing from "../../../assets/landing-page/audience-aqua.jpg"; */ 
import LandingRTFC from "../../../assets/landing-page/rtfclanding2.jpg";
import Participant from "../../../assets/landing-page/mosaic.png";
/* import Gather from "../../../assets/logos/gather.png"; */ 
import InsightsImg from "../../../assets/landing-page/table.png"; 


export const LANDING = {
  title:
    "Real Talk For Change",
  subtitle: "It was time for Boston’s historically underheard to have a voice.", 
  description:
    "Leading up to the local elections in November 2021, Real Talk For Change introduced a new civic infrastructure to engage Boston residents in community-led constructive conversations. This city-wide effort was a collaboration between the MIT Center for Constructive Communication, Cortico, and a network of local community organizations.",
  picture: LandingRTFC,
};

//Question - how do I change the titles - "Self-efficacy, impact, learning experiences, thriving communities, youth empowerment, values?"
// Supplying "background" field sets the background to the specified picture
export const HIGHLIGHT = {
  featured: {
    "Housing": {
      featured: [4119336],
      text:
        "What’s your question about the future of Boston and your place in that future?",
      search: "/voices/map-explorer?hideMap=true&topic=Housing",
    },
    "Government And Institutions": {
      featured: [4119336],
      text:
        "What experience in your life got you to this question?",
      search: "/voices/map-explorer?hideMap=true&topic=Government%20And%20Institutions",
    },
    "Public Health": {
      featured: [4119336],
      text:
        "What I want you to do next is think back to each of the questions and experiences that were just shared in our group. Find someone whose question or experience resonates with your own life. Then I want you to speak to that person and tell them why their question or experience resonated with you and share the story from your life that connects you with their experience. ",
      search: "/voices/map-explorer?hideMap=true&topic=Public%20Health",
    },
    "Inequality": {
      featured: [4119336],
      text:
        "Let’s talk a little about what we are hearing. What are you hearing in people’s experiences?",
      search: "/voices/map-explorer?hideMap=true&topic=Inequality",
    },
    "Economic Opportunity": {
      featured: [4119336],
      text:
        "Do you have any closing thoughts that you’d like to share or other general reflections on the conversation? Do you have any questions for us?",
      search: "/voices/map-explorer?hideMap=true&topic=Economic%20Opportunity",
    },
    "Education": {
      featured: [4119336],
      text:
        "In 280+ moments across conversations, alumni shared stories and dreams for the future fortified by JA’s longstanding values. Diversity and inclusion and a strong belief in youth came up most frequently as alumni shared their desire to create sustainable change around the world and in their own communities.",
      search: "/voices/map-explorer?hideMap=true&topic=Education",
    },
    "Community Life": {
      featured: [4119336],
      text:
        "In 280+ moments across conversations, alumni shared stories and dreams for the future fortified by JA’s longstanding values. Diversity and inclusion and a strong belief in youth came up most frequently as alumni shared their desire to create sustainable change around the world and in their own communities.",
      search: "/voices/map-explorer?hideMap=true&topic=Community%20Life",
    },
    "Infrastructure": {
      featured: [4119336],
      text:
        "In 280+ moments across conversations, alumni shared stories and dreams for the future fortified by JA’s longstanding values. Diversity and inclusion and a strong belief in youth came up most frequently as alumni shared their desire to create sustainable change around the world and in their own communities.",
      search: "/voices/map-explorer?hideMap=true&topic=Infastructure",
    },
    "Safety": {
      featured: [4119336],
      text:
        "In 280+ moments across conversations, alumni shared stories and dreams for the future fortified by JA’s longstanding values. Diversity and inclusion and a strong belief in youth came up most frequently as alumni shared their desire to create sustainable change around the world and in their own communities.",
      search: "/voices/map-explorer?hideMap=true&topic=Safety",
    },

  },
};



/* this is what titit had originally. 
export const HIGHLIGHT = {
  featured: {
    "Self-Efficacy": {
      featured: [ 5249595, 5247643, 5249588, 5249503],
      text:
        "What’s your question about the future of Boston and your place in that future?",
      search: "/voices/map-explorer?hideMap=true&topic=Self-Efficacy",
    },
    Impact: {
      featured: [5248931, 5247932, 5248440, 5247967, 5248035],
      text:
        "What experience in your life got you to this question?",
      search: "/voices/map-explorer?hideMap=true&topic=Impact",
    },
    "Learning Experiences": {
      featured: [5248731, 5249179, 5247988, 5248033, 5247732, 5248023],
      text:
        "What I want you to do next is think back to each of the questions and experiences that were just shared in our group. Find someone whose question or experience resonates with your own life. Then I want you to speak to that person and tell them why their question or experience resonated with you and share the story from your life that connects you with their experience. ",
      search: "/voices/map-explorer?hideMap=true&topic=Learning%20Experiences",
    },
    "Thriving Communities": {
      featured: [5248783, 5248746, 5247330, 5248467, 5248688],
      text:
        "Let’s talk a little about what we are hearing. What are you hearing in people’s experiences?",
      search: "/voices/map-explorer?hideMap=true&topic=Thriving%20Communities",
    },
    "Youth Empowerment": {
      featured: [5248047, 5249241, 5249586, 5249204, 5248712, 5247619],
      text:
        "Do you have any closing thoughts that you’d like to share or other general reflections on the conversation? Do you have any questions for us?",
      search: "/voices/map-explorer?hideMap=true&topic=Youth%20Empowerment",
    },
    Values: {
      featured: [5247827,  5247149, 5249176, 5247691, 5247956, 5248470],
      text:
        "In 280+ moments across conversations, alumni shared stories and dreams for the future fortified by JA’s longstanding values. Diversity and inclusion and a strong belief in youth came up most frequently as alumni shared their desire to create sustainable change around the world and in their own communities.",
      search: "/voices/map-explorer?hideMap=true&topic=Values",
    },
  },
};
*/ 


export const TOPIC = {
  title: "Nine key themes emerged from conversations with 320+ community members across 21 historically underheard neighborhoods.",
  link: "/insights",
  linkLabel: "Listen",
  body: [
    "After conversations were facilitated, recorded, and transcribed, a team of community fellows and researchers engaged in a rigorous process of participatory sensemaking, using digital and AI-supported tools to highlight and thematically tag the conversations.", 
    "This conversation portal is an interactive platform available to community members, journalists, policy-makers, and local government to listen to people’s experiences in a thematically organized way, identify domains of action, and translate the insights into recommended actions. The portal features these lived experiences so we can better understand our neighbors and collectively shape the future of our city."
  ],
  teaser: {
  
    Impact:
      "<p>We hear these dimensions of impact echoed across alumni experiences, including Leong Kit Chau from Hong Kong, who describes the extent of JA’s impact on his life: “So rather than the boring corporate managerial side of business, I'm actually introduced to startup and a concept of entrepreneur. And that actually brings me to who I am today.”</p><p><i>Listen to Leong’s story and learn more about alumni experiences connected to JA Impact.</i></p>",
    "Learning Experiences":
      "<p>Time and again, students like Anouru from Nigeria testify to the open horizons afforded by these experiences: “So the Company Program has actually opened up my horizon around that, made me understand that there's nothing I want to do that I can not do.”</p><p><i>Listen to Anouru’s story and learn more about alumni experiences connected to JA Learning Experiences.</i></p>",
    "Thriving Communities":
      "<p>JA youth like Leandros from Malta tell us about how their communities remained resilient during the pandemic: “We tended to play music out of our window sills, because we have a lot of windows.”</p><p><i>Listen to Leandros’s story and learn more about alumni experiences connected to Thriving Communities.</i></p>",
    "Youth Empowerment":
      "<p>Across conversations, alumni shared visions for their own futures and unpacked the ways in which JA is empowering them to achieve these goals. Marina from Brazil says, “I see myself with my own restaurant, because it’s my dream. And I think JA helped me a lot with teamwork because I’m super shy.”</p><p><i>Listen to Marina’s story and learn more about alumni experiences connected to Youth Empowerment.</i></p>",
    Values:
      "<p>Participants like Cimone from the United States, echo a shared value of humanity and the collective impulse for young people to reach out and help each other: “We're all on different parts of the globe. And I find it interesting that our communities have similar problems, our communities have similar outlooks.”</p><p><i>Listen to Cimone’s story and learn more about alumni experiences connected to JA Values.</i></p>",
    "Question": 
      "<p> Test </p>",
    },

};

export const PARTICIPANT = {
  title: "Meet Our JA Youth Advisors",
  body:
    "100+ JA Youth Advisors across 45+ countries participated in the Youth Voices conversations as facilitators and conversation participants.",
  picture: Participant
};

export const CTA = {
  title: "Conversations to Make a Difference",
  body: "Real Talk For Change introduced small group conversations with community members across the city who were too often underheard by current civic processes. These conversations were grounded in the rich practice of sharing personal stories and lived experiences and facilitated by trusted community leaders.", 
  body2: "The Real Talk For Change conversation portal is an interactive platform that features a number of deeply personal stories and experiences that were shared by community members across Boston at that time. You can explore those stories and experiences by theme, neighborhood, and key demographics.", 
  media: InsightsImg,
  link: ""
}



export const LOCATION = {
  title: "Listen to voices by neighborhood",
  label: "Stories",
  link: "/voices/map-explorer",
  body: [
    "Reaching more than 12 million young people each year through nearly half a million teachers and business volunteers, JA Worldwide is one of few organizations with the scale, experience, and passion to build a brighter future for the next generation of innovators, entrepreneurs, and leaders.",
  ],
  participants: 320,
  conversations: 62,
  tagline: "",
  descriptions: { //change these to JA worldwide. But idk if i should use the MIT map? 
    "Allston": {
      title: "Allston",
      body:
        "JA Africa is the regional operating center for JA Worldwide in sub-Saharan Africa. Over the next decade, JA Africa aims to provide one million African youth with information and resources, and to build the self-confidence and self-reliance necessary for Africa’s youth and the subcontinent to succeed.",
      link: "/voices/map-explorer?location=Allston",
      label: "Allston",
      participants: 3,
      conversations: 3,
      tagline: "Allston is close to many colleges and universities in and around the City, so it’s know for its student population. But, in recent years, many immigrants and young professionals have moved to the area."
    },
    "Back Bay": {
      title: "Back Bay",
      body:
        "JA Americas is the regional operating center for JA Worldwide in Canada, Latin America, and the Caribbean. JA Americas serves youth across a diverse group of countries through a mix of 30+ distinct programs organized around work readiness, financial literacy, and entrepreneurship.",
      link: "/voices/map-explorer?location=Back%20Bay",
      label: "Back Bay",
      participants: 3,
      conversations: 3,
      tagline: "The Back Bay is one of the most appealing neighborhoods in America. It's also a protected historic district."
    },
    "Bay Village": {
      title: "Bay Village",
      body:
        "JA Asia Pacific is the regional operating center for JA Worldwide in Asia Pacific. Private and public sector support and on-the-ground delivery of JA programs through 18 JA local areas enable JA Asia Pacific to help young people develop skills for employment and contribute to a strong and capable workforce.",
      link: "/voices/map-explorer?location=Bay%20Village",
      label: "Bay Village",
      participants: 2,
      conversations: 2,
      tagline: "The neighborhood is surrounded by Chinatown, Back Bay, the South End. and Downtown. Bay Village features tree-lined streets and shops and restaurants. One of the smallest neighborhoods in Boston makes up for its lack of size with its inviting and friendly atmosphere."
    },
    "Beacon Hill": {
      title: "Beacon Hill",
      body:
        "JA Europe is the regional operating center for JA Worldwide across Europe. As the largest European provider of entrepreneurship education programs, JA Europe brings the public and private sectors together to provide youth with experiences that promote the skills, understanding, and perspective they need to succeed in a global economy.",
      link: "/voices/map-explorer?location=Beacon%20Hill",
      label: "Beacon Hill",
      participants: 4,
      conversations: 3,
      tagline: "Beacon Hill is home to the Massachusetts State House and many historical landmarks, including the Boston African American National Historic Site. One of the oldest communities in the City gets its name from the beacon that once stood high on the hill to warn locals about invasions. It’s no surprise that Beacon Hill is a protected historic district."
    },
    "Brighton": {
      title: "Brighton",
      body:
        "INJAZ Al-Arab JA MENA is the regional operating center for JA Worldwide across the Middle East and North Africa. INJAZ provides education and training in entrepreneurial skills and teaches programs to develop financial literacy and soft skills that equip young people to run their own businesses and find employment.",
      link: "/voices/map-explorer?location=Brighton",
      label: "Brighton",
      participants: 4,
      conversations: 3,
      tagline: "Beacon Hill is home to the Massachusetts State House and many historical landmarks, including the Boston African American National Historic Site. One of the oldest communities in the City gets its name from the beacon that once stood high on the hill to warn locals about invasions. It’s no surprise that Beacon Hill is a protected historic district."
    },
    "Charlestown": {
      title: "Charlestown",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=Charlestown",
      label: "Charlestown",
      participants: 3,
      conversations: 3,
      tagline: "Charlestown is on the north side of the City, on the banks of Boston Harbor and the Mystic River.  The neighborhood has historical roots, but it has turned into a busy, modern-day neighborhood."
    },
    "Chinatown-Leather District": {
      title: "Chinatown-Leather District",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=Chinatown-Leather%20District",
      label: "Chinatown-Leather District",
      participants: 1,
      conversations: 1,
      tagline: "Located along the southern edge of Downtown, Boston's Chinatown is a commercial and cultural hub for the City's Chinese community. The Leather District is a small neighborhood just east of Chinatown, nestled between Dewey Square and Kneeland Street. The nine distinct blocks are noted for their 19th century brick warehouse structures."
    },
    "Dorchester": {
      title: "Dorchester",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=Dorchester",
      label: "Dorchester",
      participants: 169,
      conversations: 63,
      tagline: "The largest neighborhood in Boston is also one of the most diverse. Long-time residents mingle together with new immigrants from Vietnam, Cape Verde, Ireland, and many other countries. This wonderful mix of residents from all cultures and backgrounds makes it an incredibly vibrant place to live, work, and spend time. Former residents can often be seen and heard claiming to be “OFD” — Originally From Dorchester."
    },
    "Downtown": {
      title: "Downtown",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=Downtown",
      label: "Downtown",
      participants: 5,
      conversations: 4,
      tagline: "Located in the heart of Boston, Downtown has served as Boston's hub since the 1700s. Home to City Hall, numerous corporate headquarters, condos and apartments, and some of Boston’s most beloved tourist attractions — including the historic Freedom Trail and Faneuil Hall — something is always going on Downtown."
    },
    "East Boston": {
      title: "East Boston",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=East%20Boston",
      label: "East Boston",
      participants: 15,
      conversations: 12,
      tagline: "East Boston was once a center for shipbuilding, but it has always been a neighborhood of immigrants. You can see the diversity in the neighborhood’s many ethnic restaurants."
    },
    "Fenway-Kenmore": {
      title: "Fenway-Kenmore",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=Fenway-Kenmore",
      label: "Fenway-Kenmore",
      participants: 6,
      conversations: 6,
      tagline: "Fenway-Kenmore is most recognized as the home of Fenway Park and the Red Sox. But, it’s also home to cultural institutions like the Museum of Fine Arts and Symphony Hall. You’ll also find the nation’s first public school, Boston Latin School. There are also several schools for higher education. Students and young professionals love the area for the clubs on Lansdowne Street. Frederick Law Olmsted designed the neighborhood’s main green space, the Back Bay Fens. The Fenway surrounds this lush park."
    },
    "Hyde Park": {
      title: "Hyde Park",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=Hyde%20Park",
      label: "Hyde Park",
      participants: 10,
      conversations: 8,
      tagline: "Hyde Park is Boston’s southernmost neighborhood. The neighborhood was annexed into the City in 1912. The Neponset River runs through its center. You can see the strong community spirit in the neighborhood’s many shops and restaurants."
    },
    "Jamaica Plain": {
      title: "Jamaica Plain",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=Jamaica%20Plain",
      label: "Jamaica Plain",
      participants: 11,
      conversations: 10,
      tagline: "Locals call Jamaica Plain “JP.” The classic streetcar suburb that has become one of Boston’s most dynamic neighborhoods. Surrounded by the Emerald Necklace, Arnold Arboretum, Franklin Park and Jamaica Pond, it is the perfect place to visit, shop, dine, and live all year round!"
    },
    "Mattapan": {
      title: "Mattapan",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=Mattapan",
      label: "Mattapan",
      participants: 20,
      conversations: 16,
      tagline: "In the early 1600s, Mattapan started as community for Native Americans known as the Mattahunt Tribe. At the dawn of 21st century, immigrants traveling in New England found Mattapan to be a \"good place to sit.\" Irish, Jewish, and Haitian immigrants called the neighborhood home. Now, Mattapan is home to a large African American and Caribbean community."
    },
    "Mid-Dorchester": {
      title: "Mid-Dorchester",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=Mid-Dorchester",
      label: "Mid-Dorchester",
      participants: 6,
      conversations: 6,
      tagline: "A small part of the city's largest neighborhood. The City is bringing lots of new development to the area, including housing and light industrial and commercial opportunities. With the improvement of the Fairmount/ Indigo Line, there's now easy access to jobs and the downtown financial area."
    },
    "Mission Hill": {
      title: "Mission Hill",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=Missino%20Hill",
      label: "Mission Hill",
      participants: 3,
      conversations: 3,
      tagline: "One of Boston's most convenient and diverse neighborhoods. Even neighborhood homes are diverse in Mission Hill. New condos, brick row houses, and triple-decker homes make the neighborhood an architectural landmark. You can also find Mission Church in Mission Hill."
    },
    "North End": {
      title: "North End",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=North%20End",
      label: "North End",
      participants: 4,
      conversations: 3,
      tagline: "The North End is a hub for the City's Italian-American community. Its character is woven through the neighborhoods narrow, yet vibrant streets. The North End also boasts some of the City's best restaurants and old-world cafes."
    },
    "Roslindale": {
      title: "Roslindale",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=Roslindale",
      label: "Roslindale",
      participants: 7,
      conversations: 7,
      tagline: "Residents once called Roslindale the “garden suburb” of Boston. Today, they still enjoy the neighborhood’s natural beauty. Here you’ll find the Arnold Arboretum. It's a 265-acre park that’s part of Boston’s Emerald Necklace. Many of the area’s colonial homes are now condos that house the City’s growing population. Roslindale Village is the original Main Street district. There's a vibrant shopping and dining area with unique shops and bistros. Downtown is just a quick ride away on the MBTA Orange Line and Commuter Rail."
    },
    "Roxbury": {
      title: "Roxbury",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=Roxbury",
      label: "Roxbury",
      participants: 34,
      conversations: 28,
      tagline: "This former farming community is home to the Shirley Eustis House. It’s the nation’s only remaining country house that a British Royal Colonial Governor built. Today, Roxbury is the heart of Black culture in Boston."
    },
    "South Boston": {
      title: "South Boston",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=South%20Boston",
      label: "South Boston",
      participants: 5,
      conversations: 5,
      tagline: "An urban neighborhood with a strong sense of history and tradition. South Boston is home to Dorchester Heights and other hills where George Washington’s army set up cannon to force the British out of Boston in 1776.  The cannon were dragged from Fort Ticonderoga by Henry Knox.  Dorchester Heights is now a National Park."
    },
    "South End": {
      title: "South End",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=South%20End",
      label: "South End",
      participants: 14,
      conversations: 11,
      tagline: "The South End is truly a cosmopolitan neighborhood. It is just minutes away from Downtown and the Back Bay, and has recently become one of the City’s most popular neighborhoods."
    },
    "West End": {
      title: "West End",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=West%20End",
      label: "South End",
      participants: 4,
      conversations: 3,
      tagline: "The West End has always been an ethnically diverse and vibrant neighborhood. It’s home to prominent Boston institutions, including Massachusetts General Hospital and TD Garden. With a bustling business community and a growing residential population, the West End is the up-and-coming neighborhood in Boston."
    },
    "West Roxbury": {
      title: "West Roxbury",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=West%20Roxbury",
      label: "West Roxbury",
      participants: 3,
      conversations: 3,
      tagline: "West Roxbury is in the southwest corner of the City of Boston. Today, people know the neighborhood for its civic activism and youth programs. West Roxbury has a suburban vibe with its tree-lined streets and single-family homes."
    },
    "Wharf District": {
      title: "Wharf District",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=Wharf%20District",
      label: "Wharf District",
      participants: "0",
      conversations: "0",
      tagline: "The Wharf District was formed during the “Big Dig Project” with the demolition of the Central Artery and creation of the Greenway. The Financial-Town Cove area joined together with the Waterfront-Wharfs. Together, they became a community  with shared and common interests, goals, and architecture. The history of the area back to colonial times."
    },
    "Other Connection to Boston": {
      title: "Other Connection to Boston",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=Other%20Connection%20to%20Boston",
      label: "Other Connection to Boston",
    },
    "Undisclosed Neighborhood": {
      title: "Undisclosed Neighborhood",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=Undisclosed%20Neighborhood",
      label: "Undisclosed Neighborhood",
    },
  },
};


/* What titi had for JA wordlwide 
export const LOCATION = {
  title: "Six JA Regions",
  label: "JA Worldwide",
  link: "/voices/map-explorer",
  body: [
    "Reaching more than 12 million young people each year through nearly half a million teachers and business volunteers, JA Worldwide is one of few organizations with the scale, experience, and passion to build a brighter future for the next generation of innovators, entrepreneurs, and leaders.",
  ],
  descriptions: {
    "JA Africa": {
      title: "JA Africa",
      body:
        "JA Africa is the regional operating center for JA Worldwide in sub-Saharan Africa. Over the next decade, JA Africa aims to provide one million African youth with information and resources, and to build the self-confidence and self-reliance necessary for Africa’s youth and the subcontinent to succeed.",
      link: "/voices/map-explorer?location=JA%20Africa",
      label: "JA Africa",
    },
    "JA Americas": {
      title: "JA Americas",
      body:
        "JA Americas is the regional operating center for JA Worldwide in Canada, Latin America, and the Caribbean. JA Americas serves youth across a diverse group of countries through a mix of 30+ distinct programs organized around work readiness, financial literacy, and entrepreneurship.",
      link: "/voices/map-explorer?location=JA%20Americas",
      label: "JA Americas",
    },
    "JA Asia Pacific": {
      title: "JA Asia Pacific",
      body:
        "JA Asia Pacific is the regional operating center for JA Worldwide in Asia Pacific. Private and public sector support and on-the-ground delivery of JA programs through 18 JA local areas enable JA Asia Pacific to help young people develop skills for employment and contribute to a strong and capable workforce.",
      link: "/voices/map-explorer?location=JA%20Asia%20Pacific",
      label: "JA Asia Pacific",
    },
    "JA Europe": {
      title: "JA Europe",
      body:
        "JA Europe is the regional operating center for JA Worldwide across Europe. As the largest European provider of entrepreneurship education programs, JA Europe brings the public and private sectors together to provide youth with experiences that promote the skills, understanding, and perspective they need to succeed in a global economy.",
      link: "/voices/map-explorer?location=JA%20Europe",
      label: "JA Europe",
    },
    "INJAZ Al-Arab JA MENA": {
      title: "INJAZ Al-Arab JA MENA",
      body:
        "INJAZ Al-Arab JA MENA is the regional operating center for JA Worldwide across the Middle East and North Africa. INJAZ provides education and training in entrepreneurial skills and teaches programs to develop financial literacy and soft skills that equip young people to run their own businesses and find employment.",
      link: "/voices/map-explorer?location=INJAZ%20Al-Arab%20JA%20MENA",
      label: "INJAZ Al-Arab JA MENA",
    },
    "Junior Achievement USA": {
      title: "Junior Achievement USA",
      body:
        "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=Junior%20Achievement%20USA",
      label: "Junior Achievement USA",
    },
  },
};


*/ 


export const STRUCTURE = {
  featured: {
    "Question": {
      featured: [3172916, 3204259, 3204255, 3197856, 3210622, 4077476],
      text:
        "What’s your question about the future of Boston and your place in that future?",
      search: "/voices/map-explorer?hideMap=true&prompt=Question",
    },
    "Experience": {
      featured: [3166739, 3166735, 3197899, 3210617, 3602790, 4077488],
      text:
        "What experience in your life got you to this question?",
      search: "/voices/map-explorer?hideMap=true&prompt=Experience",
    },
    "Resonating Experience": {
      featured: [5244666, 5244685, 5242549, 5244437, 5244447, 5244803],
      text:
        "Which of the experiences shared resonated with you?",
      search: "/voices/map-explorer?hideMap=true&prompt=Resonating%20Experience",
    },
    "Drawing Connections": {
      featured: [5244420, 5244441, 5244535, 5244819, 5242569, 5242570],
      text:
        "What are you hearing in people’s experiences?",
      search: "/voices/map-explorer?hideMap=true&prompt=Drawing%20Connections",
    },
    "Takeaway": {
      featured: [5244633, 5244671, 5244897, 5244985, 5244669, 5244986],
      text:
        "What are you taking away from the conversation?",
      search: "/voices/map-explorer?hideMap=true&prompt=Takeaway",
    },
  },
}; 





// Supplying "background" field sets the background to the specified picture
export const STRUCTURE2 = {
  title: "Our Vision for Boston",
  body:
    "<p>RealTalk@MIT is a space for our community to have nuanced and authentic dialogue. Our vision is to build a communication infrastructure within MIT that will harness the power of small group-facilitated conversations as a vehicle for informing decisions that improve the quality of Institute life.</p><p>The aim of the RealTalk@MIT pilot was to engage a small number of departments, labs, centers, and other MIT groups in the Real Talk process to better understand how it can be adapted and scaled for a larger, Institute-wide effort.</p>",
  featured: [
    {
      featured: [3602779],
      text:
        "In the conversations, we prompted participants to choose one or more of the values that were introduced in the <a href='https://web.mit.edu/about/values-statement/' target='_blank' style={{ color: 'inherit', textDecoration: 'underline', textDecorationColor: '#A31F34', textUnderlineOffset: '3px' }} >MIT Values Statement</a>. Keeping these values in mind, they were invited to recall an experience from their life at MIT when they felt the value they selected was celebrated and they perceived it as a positive experience overall.",
      link: "/insights/questions/celebrated",
      label: "Explore Insight",
    },
    {
      featured: [3602779],
      text:
        "Participants were next invited to recall an experience when the value they selected was not aligned and they experienced tension or even a direct conflict.",
      link: "/insights/questions/friction",
      label: "Explore Insight",
    },
    {
      featured: [3602779],
      text:
        "After hearing everyone’s experiences and stories, the conversation opened up for participants to reflect on the connections, tensions, and commonalities that emerged. They were invited to share suggestions on what needs to change.",
      link: "insights/questions/change",
      label: "Explore Insight",
    },
    {
      featured: [3602779],
      text:
        "In the final part of the conversation, participants engaged in imagining what the future of MIT might look like and what is their place in that future.",
      link: "insights/questions/future",
      label: "Explore Insight",
    },
  ],
};


